<template>
  <!-- OPERATIONS UNIT READ ONLY IT SELF DATA -->
  <div v-if="$role(['director', 'service_advisor', 'head_operation', 'operation_unit', 'logistic'], $store.state.user.role)">
    <v-row no-gutters>
      <v-col cols="12" class="d-flex jutify-space-between col-sm-2 py-4 pr-0 pr-sm-2">
        <v-card :class="$role(['service_advisor', 'head_operation'], $store.state.user.role) ? 'primary' : 'grey'" class="d-flex align-center justify-center px-4 fill-width indigo caption font-weight-bold py-2" dark outlined @click.prevent="$role(['service_advisor', 'head_operation'], $store.state.user.role) ? TRX_SHOW() : false">
          <v-icon small class="mr-2">
            mdi-plus
          </v-icon>
          New Order
        </v-card>
      </v-col>
      <v-col cols="12" class="d-flex jutify-space-between col-sm-2 py-4 px-2">
        <v-card :class="$role(['service_advisor', 'head_operation', 'operation_unit'], $store.state.user.role) ? 'green' : 'grey'" class="d-flex align-center justify-center px-4 caption font-weight-bold mr-1 py-2" dark outlined style="width: 49%;" @click.prevent="$role(['service_advisor', 'head_operation', 'operation_unit'], $store.state.user.role) ? ExportExcel(true) : false">
          <v-icon small class="mr-2">
            mdi-microsoft-excel
          </v-icon>
          Export
        </v-card>
        <v-card :class="$role(['service_advisor', 'head_operation'], $store.state.user.role) ? 'green' : 'grey'" class="d-flex align-center justify-center px-4 caption font-weight-bold ml-1 py-2" dark outlined style="width: 49%;" @click.prevent="$role(['service_advisor', 'head_operation'], $store.state.user.role) ? ExportExcel('all') : false">
          <v-icon small class="mr-2">
            mdi-microsoft-excel
          </v-icon>
          All
        </v-card>
      </v-col>
      <v-col cols="12" class="col-sm-2 white">
        <div class="pt-4 pb-0 pb-sm-4">
          <v-text-field
            v-model="tableSearch"
            outlined
            dense
            hide-details
            placeholder="Search ..."
            prepend-inner-icon="mdi-magnify"
          />
        </div>
      </v-col>
      <v-col cols="6" class="col-sm-2 white">
        <div class="px-2 pt-4 pb-0 pb-sm-4">
          <v-menu
            ref="menu"
            v-model="menu"
            :return-value.sync="dates"
            left
            offset-y
            :close-on-content-click="false"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="dateRangeText"
                label="Date Ranges"
                append-icon="mdi-calendar-outline"
                readonly
                outlined
                dense
                hide-details
                clearable
                v-bind="attrs"
                v-on="on"
                @click:clear="dates = [], TRX_GET(tableSearch)"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              no-title
              light
              class="force-light-font"
              range
            >
              <v-spacer />
              <v-btn
                text
                class="text-capitalize"
                small
                @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                :disabled="dates.length !== 2"
                text
                class="text-capitalize"
                small
                @click="$refs.menu.save(dates), TRX_GET(tableSearch)"
              >
                Ok
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
      </v-col>
      <v-col cols="6" class="col-sm-2 white">
        <div class="px-0 px-sm-2 pt-4 pb-0 pb-sm-4">
          <v-select
            v-model.number="storeUnit"
            :disabled="$role(['operation_unit', $store.state.user.role]) ? true : false"
            :items="[{ name: 'All', id: 0 }].concat($store.state.storeData)"
            item-text="name"
            item-value="id"
            outlined
            dense
            hide-details
            label="Operations Unit"
          />
        </div>
      </v-col>
      <!-- <v-col cols="6" class="col-sm-2 white">
        <div class="px-0 px-sm-2 pt-4 pb-0 pb-sm-4">
          <v-select
            v-model="tableSearchPayment"
            :items="[{ name: 'All Payment', id: '' }].concat($store.state.payment)"
            item-text="name"
            item-value="id"
            outlined
            dense
            hide-details
            label="Payment"
          />
        </div>
      </v-col> -->
      <v-col cols="6" class="col-sm-2 white">
        <div class="px-2 py-4 pb-sm-4">
          <v-select
            v-model="tableSearchStatus"
            :items="[{ name: 'All Status', value: null }, { name: 'Waiting Confirmation', value: 0 }, { name: 'Processed', value: 1 }, { name: 'SA Verification', value: 2 }, { name: 'Waiting Payment', value: 3 }, { name: 'Completed', value: 4 }]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            label="Order Status"
          />
        </div>
      </v-col>
      <v-col cols="12" class="pt-0 pt-sm-4">
        <v-data-table
          dense
          :headers="headers"
          :items="table"
          item-key="id"
          :server-items-length="tableTotal"
          :page.sync="tablePage"
          :items-per-page.sync="tableLimit"
          :options.sync="options"
          :footer-props="{
            itemsPerPageOptions: [8, 50, 100]
          }"
          :loading="tableLoading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.id`]="{ item }">
            <div class="caption">
              <div class="d-flex align-center">
                RDMS/{{ (item.store || 0) }}/TRX/{{ item.id }}
                <v-chip v-if="parseInt(item.platform)" color="primary" x-small class="ml-2">
                  Booking
                </v-chip>
              </div>
              <div v-if="item.store_unit_name">
                {{ item.store_unit_name }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.platform`]="{ item }">
            <div class="py-2">
              <div>
                {{ item.customer_name || 'None' }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.vehicle_plat`]="{ item }">
            <div class="caption-small">
              {{ item.vehicle_plat }}, {{ item.vehicle_brand }} {{ item.vehicle_type }} {{ item.vehicle_year }}
            </div>
          </template>
          <template v-slot:[`item.customer_name`]="{ item }">
            <div class="caption-small">
              {{ item.customer_name }}, {{ item.customer_email }} {{ item.customer_phone }}
            </div>
          </template>
          <template v-slot:[`item.paid`]="{ item }">
            <div class="text-right caption-small">
              {{ $price(item.paid) }}
              <div v-if="item.payment_description" class="caption-small font-italic fill-width align-left orange--text">
                #{{ item.payment_description }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.amount_total`]="{ item }">
            <div class="text-right caption-small">
              {{ $price(item.amount_total) }}
            </div>
          </template>
          <template v-slot:[`item.payment_type`]="{ item }">
            <div class="caption-small">
              {{ item.payment_type }}
            </div>
          </template>
          <template v-slot:[`item.created`]="{ item }">
            <div class="caption-small">
              {{ $localDT(item.created, 'datelocal') }}
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div class="d-flex align-end flex-column justify-center my-1">
              <div class="d-flex align-center">
                <v-chip v-if="parseInt(item.status) === 3" :color="(parseInt(item.amount_total) > parseInt(item.paid)) ? 'red' : ($TrxStatus(item.status).color)" outlined dark small>
                  <v-icon x-small class="mr-1">
                    {{ (parseInt(item.amount_total) > parseInt(item.paid)) ? 'mdi-cash-remove' : $TrxStatus(item.status).icon }}
                  </v-icon>
                  {{ (parseInt(item.amount_total) > parseInt(item.paid)) ? 'Waiting Payment' : $TrxStatus(item.status).name }}
                </v-chip>
                <v-chip v-else :color="$TrxStatus(item.status).color" outlined dark small>
                  <v-icon x-small class="mr-1">
                    {{ $TrxStatus(item.status).icon }}
                  </v-icon>
                  {{ $TrxStatus(item.status).name }}
                </v-chip>
              </div>
            </div>
          </template>
          <template v-slot:[`item.option`]="{ item }">
            <div style="min-width: 100px;">
              <v-btn v-if="$role(['director', 'service_advisor', 'head_operation', 'operation_unit'], $store.state.user.role)" :color="$role(['director', 'service_advisor', 'head_operation', 'operation_unit'], $store.state.user.role) ? 'orange' : 'grey'" depressed small dark class="rounded text-capitalize" @click.prevent="$role(['director', 'service_advisor', 'head_operation', 'operation_unit'], $store.state.user.role) ? TRX_SHOW(item) : false">
                <v-icon small class="mr-2">
                  mdi-information-outline
                </v-icon>
                Detail
              </v-btn>
            </div>
          </template>
          <template v-slot:footer>
            <div :class="[$vuetify.theme.dark ? '' : 'grey lighten-4']" class="pt-2 pb-3 px-4 px-md-8">
              <v-divider class="mt-2 mb-3" />
              <div class="d-flex justify-space-between flex-column flex-sm-row">
                <div class="d-flex align-center justify-center justify-sm-start font-weight-bold">
                  Summary Reports :
                </div>
                <div class="d-flex justify-center align-center align-sm-end flex-column body-2">
                  <span class="font-weight-bold caption-small">
                    Subtotal : {{ parseInt(tableSummary.subtotal) | price }}
                  </span>
                  <v-divider class="my-1" style="width: 75px;" />
                  <span class="font-weight-bold caption-small">
                    Total {{ parseInt(tableSummary.total) | price }}
                  </span>
                </div>
              </div>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogTrx.show"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="rounded-0">
        <v-toolbar
          dark
          color="indigo"
          class="elevation-0 fill-width"
          style="position: fixed;top: 0px;left: 0px;z-index: 2;"
        >
          <v-btn
            icon
            dark
            @click="ClearTrx(), TRX_GET(tableSearch), dialogTrx.show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="d-flex justify-space-between align-center fill-width">
            {{ dialogTrx.data ? parseInt(dialogTrx.data.id) ? ('View Order RDMS/' + parseInt(dialogTrx.data.store || 0) + '/TRX/' + parseInt(dialogTrx.data.id)) : 'Create New Order' : 'Undefined' }}
            <v-btn v-if="dialogTrx.data ? (!TRXEDITED && parseInt(dialogTrx.data.id) && parseInt(dialogTrx.data.status) >= 1) : false" depressed class="rounded-pill text-capitalize" light color="white" @click.prevent="dialogStruk.show = true">
              Print
            </v-btn>
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-container v-if="dialogTrx.data" class="pt-16">
          <v-row no-gutters>
            <v-col cols="12" class="col-sm-6 pa-4">
              <div v-if="GENERATED_ID" cols="12" class="mt-0 pb-4 mt-sm-4 d-flex flex-column justify-center flex-sm-row justify-sm-space-between grey-sm lighten-4">
                <div class="d-flex flex-column">
                  <barcode :value="GENERATED_ID" :display-value="false" :width="2.3" :height="50" class="barcode-class">
                    ERROR
                  </barcode>
                  <div class="d-flex justify-center title font-weight-bold fill-width align-center">
                    RDMS/{{ dialogTrx.data.store + '/' + GENERATED_ID }}
                  </div>
                </div>
                <template v-if="dialogTrx.data">
                  <div v-if="parseInt(dialogTrx.data.id)" :class="[((TotalAmount - parseInt(dialogTrx.data.paid)) > 0) && parseInt(dialogTrx.data.status) === 3 ? 'red' : $TrxStatus(dialogTrx.data.status).color]" class="white--text d-flex flex-column align-start justify-center py-4 py-sm-1 mt-4 mt-sm-0 px-10 text-uppercase">
                    <div class="d-flex align-center pb-2">
                      <v-icon small class="mr-2" color="white">
                        {{ ((TotalAmount - parseInt(dialogTrx.data.paid)) > 0) && parseInt(dialogTrx.data.status) === 3 ? 'mdi-cash-remove' : $TrxStatus(dialogTrx.data.status).icon }}
                      </v-icon>
                      {{ ((TotalAmount - parseInt(dialogTrx.data.paid)) > 0) && parseInt(dialogTrx.data.status) === 3 ? 'Waiting Payment' : $TrxStatus(dialogTrx.data.status).name }}
                    </div>
                    <v-chip v-if="$store.state.user.role === 'director' ? (parseInt(dialogTrx.data.status) === 3 ? true : false) : (((TotalAmount - parseInt(dialogTrx.data.paid)) > 0) || (parseInt(dialogTrx.data.id) && parseInt(dialogTrx.data.status) < 3))" :color="$role(['director', 'service_advisor', 'head_operation'], $store.state.user.role) ? 'white' : 'grey'" small class="red--text ml-4 c-pointer rounded-0" @click.prevent="$role(['director', 'service_advisor', 'head_operation'], $store.state.user.role) ? ShowUpdateTrxStatus() : false">
                      Update Status
                    </v-chip>
                  </div>
                </template>
              </div>
              <v-divider class="mb-4" />
              <div class="d-flex align-center">
                <v-icon class="mr-2">
                  mdi-information-outline
                </v-icon>
                Detail
              </div>

              <v-divider class="mt-4" />
              <div v-if="TRXEDITED" class="black pa-4">
                <v-autocomplete
                  v-model="customer.selected"
                  :search-input.sync="customer.query"
                  :items="customer.result"
                  item-text="detailname"
                  outlined
                  dense
                  dark
                  hide-details
                  return-object
                  clearable
                  autocomplete="none"
                  prepend-icon="mdi-magnify"
                  append-outer-icon="mdi-plus"
                  class="rounded-0"
                  label="Search Customer"
                  @click:append-outer="ShowCustomer()"
                />
              </div>
              <v-divider class="mb-4" />
              <div v-if="customer.selected" class="d-flex fill-width flex-column flex-sm-row justify-sm-space-between pa-4 grey darken-1 mb-4">
                <div class="d-flex pb-4 pb-sm-0">
                  <div style="width: 100px!important">
                    <v-img
                      :src="customer.selected.img"
                      aspect-ratio="1"
                      class="grey lighten-2"
                    />
                  </div>
                  <div class="pl-4">
                    <div class="d-flex align-center text-uppercase">
                      <v-icon class="mr-1" color="white">
                        mdi-barcode
                      </v-icon>
                      <span class="body-2 white--text font-weight-bold">
                        RDMS/CUSTOMER/{{ customer.selected.id }}
                      </span>
                    </div>
                    <div class="font-weight-bold mb-2 white--text">
                      {{ customer.selected.name }}
                    </div>
                    <div v-if="TRXEDITED" class="caption white px-2 py-1 text-center">
                      {{ parseInt(customer.selected.type) === 1 ? 'Business' : 'Regular Customer' }}
                    </div>
                    <div v-else class="d-flex fill-width">
                      <div class="caption white px-2 py-1 text-center mr-2">
                      {{ parseInt(dialogTrx.data.customer_type) === 1 ? 'Business' : 'Regular Customer' }}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="d-flex align-center caption white--text font-weight-bold">
                    <v-icon small class="mr-2" color="white">
                      mdi-cellphone
                    </v-icon>
                    {{ customer.selected.phone }}
                  </div>
                  <div class="d-flex align-center caption white--text font-weight-bold">
                    <v-icon small class="mr-2" color="white">
                      mdi-email-outline
                    </v-icon>
                    {{ customer.selected.email }}
                  </div>
                  <div class="d-flex align-center pt-1">
                    <v-btn
                      v-if="customer.selected.phone"
                      :href="'https://api.whatsapp.com/send?phone=' + customer.selected.phone + '&text=Hi%20' + customer.selected.name + '...'"
                      target="_blank"
                      x-small
                      color="green"
                      dark
                      depressed
                      class="mr-2 text-capitalize rounded-pill white--text"
                    >
                      <v-icon x-small class="mr-1">
                        mdi-whatsapp
                      </v-icon>
                      Whatsapp
                    </v-btn>
                    <v-btn
                      v-if="customer.selected.phone"
                      :href="'tel:' + customer.selected.phone"
                      target="_blank"
                      x-small
                      color="blue"
                      dark
                      depressed
                      class="text-capitalize rounded-pill white--text"
                    >
                      <v-icon x-small class="mr-1">
                        mdi-phone
                      </v-icon>
                      Call
                    </v-btn>
                  </div>
                </div>
              </div>
              <div v-if="customer.selected" class="pt-0">
                <div class="pb-6">
                  <div class="d-flex fill-width align-center">
                    <v-icon class="mr-2">
                      mdi-map-marker-outline
                    </v-icon>
                    Service Address
                  </div>
                  <div class="font-weight-bold pt-4">
                    <v-textarea
                      v-model="dialogTrx.data.shipping_address"
                      dense
                      hide-details
                      rows="2"
                      outlined
                      label="Address"
                    />
                  </div>
                </div>
                <v-autocomplete
                  v-model="dialogVehicle.selected"
                  :disabled="parseInt(dialogTrx.data.status) ? true : false"
                  :items="dialogVehicle.data"
                  item-text="vehicle_fullname"
                  outlined
                  dense
                  hide-details
                  return-object
                  clearable
                  autocomplete="none"
                  prepend-icon="mdi-car"
                  append-outer-icon="mdi-plus"
                  class="rounded-0"
                  label="Selected Vehicle"
                  @click:append-outer="ShowVehicle()"
                />
                <div class="pt-4">
                  <div class="d-flex align-center">
                    <v-icon class="mr-2">
                      mdi-note-text-outline
                    </v-icon>
                    Catatan Keluhan
                  </div>
                  <v-divider class="my-4" />
                  <div class="grey lighten-4 grey--text text--darken-2 pa-4 mb-4 rounded-lg">
                    <v-textarea
                      v-model="dialogTrx.data.notes"
                      :disabled="parseInt(dialogTrx.data.status) >= 3"
                      rows="3"
                      outlined
                      dense
                      hide-details
                      label="Tulis keluhan"
                    />
                  </div>
                </div>
              </div>

              <div class="pb-4">
                <div class="d-flex align-center py-4">
                  <v-icon class="mr-2">
                    mdi-information-outline
                  </v-icon>
                  Service & Guarantee Information
                </div>
                <v-divider class="mt-0 mb-4" />
                <div class="fill-width">
                  SERVICE
                  <v-row no-gutters class="mt-2">
                    <v-col cols="12" class="col-sm-4">
                      <v-menu
                        ref="menuDateService"
                        v-model="menuDateService"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="dialogTrx.data.service_date"
                            readonly
                            outlined
                            dense
                            hide-details
                            prepend-icon="mdi-calendar"
                            label="Date"
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="dialogTrx.data.service_date"
                          :disabled="parseInt(dialogTrx.data.status) >= 3"
                          no-title
                          scrollable
                          @input="menuDateService = false"
                        />
                      </v-menu>
                    </v-col>
                    <v-col cols="12" class="col-sm-4 px-4">
                      <v-menu
                        ref="menuTime"
                        :close-on-content-click="true"
                        :nudge-left="40"
                        left
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="dialogTrx.data.service_time"
                            outlined
                            readonly
                            dense
                            hide-details
                            prepend-inner-icon="mdi-clock-time-four-outline"
                            placeholder="Service Time"
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <v-time-picker
                          v-model="dialogTrx.data.service_time"
                          :disabled="parseInt(dialogTrx.data.status) >= 3"
                          format="24hr"
                          scrollable
                          @click:minute="$refs.menuTime.save(dialogTrx.data.service_time)"
                        />
                      </v-menu>
                    </v-col>
                    <v-col cols="12" class="col-sm-4">
                      <v-text-field
                        v-model.number="dialogTrx.data.service_km"
                        :disabled="parseInt(dialogTrx.data.status) >= 3"
                        outlined
                        dense
                        hide-details
                        suffix="Km"
                        label="Kilometers"
                        @keypress="$NumOnly"
                      />
                    </v-col>
                  </v-row>
                  <v-divider class="my-4" />
                  NEXT SERVICE
                  <v-row no-gutters class="mt-2 mb-4">
                    <v-col cols="12" class="col-sm-6 pr-2">
                      <v-menu
                        ref="menuDateServiceNext"
                        v-model="menuDateServiceNext"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="dialogTrx.data.service_date_next"
                            readonly
                            outlined
                            dense
                            hide-details
                            prepend-icon="mdi-calendar"
                            label="Date"
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="dialogTrx.data.service_date_next"
                          :disabled="parseInt(dialogTrx.data.status) >= 3"
                          no-title
                          scrollable
                          @input="menuDateServiceNext = false"
                        />
                      </v-menu>
                    </v-col>
                    <v-col cols="12" class="col-sm-6 pl-2">
                      <v-text-field
                        v-model.number="dialogTrx.data.service_km_next"
                        :disabled="parseInt(dialogTrx.data.status) >= 3"
                        outlined
                        dense
                        hide-details
                        suffix="Km"
                        label="Kilometers"
                        @keypress="$NumOnly"
                      />
                    </v-col>
                  </v-row>
                  GUARANTEE EXPIRED
                  <v-row no-gutters class="mt-2 mb-8">
                    <v-col cols="12" class="col-sm-6 pr-2">
                      <v-menu
                        ref="menuDateGuarantee"
                        v-model="menuDateGuarantee"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="dialogTrx.data.guarantee_date"
                            readonly
                            outlined
                            dense
                            hide-details
                            prepend-icon="mdi-calendar"
                            label="Date"
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="dialogTrx.data.guarantee_date"
                          :disabled="parseInt(dialogTrx.data.status) >= 3"
                          no-title
                          scrollable
                          @input="menuDateGuarantee = false"
                        />
                      </v-menu>
                    </v-col>
                    <v-col cols="12" class="col-sm-6 pl-2">
                      <v-text-field
                        v-model.number="dialogTrx.data.guarantee_km"
                        :disabled="parseInt(dialogTrx.data.status) >= 3"
                        outlined
                        dense
                        hide-details
                        suffix="Km"
                        label="Kilometers"
                        @keypress="$NumOnly"
                      />
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-col>
            <v-col cols="12" class="col-sm-6 pa-4">
              <div class="d-flex align-center">
                <v-icon class="mr-2">
                  mdi-package-variant-closed
                </v-icon>
                Order
              </div>

              <v-divider class="mt-4" />
              <div class="black pa-4">
                <v-select
                  v-model.number="dialogTrx.data.store"
                  :disabled="!$role(['service_advisor', 'head_operation'], $store.state.user.role) || parseInt(dialogTrx.data.status) >= 3"
                  :items="$store.state.storeData"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  dark
                  hide-details
                  class="rounded-0"
                  label="Select Operation Unit"
                />
                <div>
                  <v-autocomplete
                    v-model="dialogTrx.data.store_users"
                    :disabled="!$role(['service_advisor', 'head_operation', 'operation_unit'], $store.state.user.role) || parseInt(dialogTrx.data.status) >= 3"
                    :items="storeUsers"
                    filled
                    chips
                    hide-details
                    dark
                    color="blue-grey lighten-2"
                    label="Account in Charge"
                    item-text="fullname"
                    item-value="idhash"
                    multiple
                    @focus="LOAD_USERS()"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        small
                        @click="data.select"
                        @click:close="remove(data.item)"
                      >
                        <v-avatar left>
                          <v-img :src="data.item.img" />
                        </v-avatar>
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-avatar>
                        <img :src="data.item.img">
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name" />
                        <v-list-item-subtitle v-html="data.item.role" />
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </div>
              </div>
              <v-divider class="my-4" />
              <div class="black pa-4">
                <v-autocomplete
                  v-model="items.selected"
                  :disabled="parseInt(dialogTrx.data.status) >= 3"
                  :search-input.sync="items.query"
                  :items="items.result"
                  item-text="item_fullname"
                  outlined
                  dense
                  hide-details
                  return-object
                  clearable
                  dark
                  append-icon="mdi-barcode"
                  class="rounded-0"
                  label="Add Service & Parts (from Warehouse) ..."
                />
              </div>
              <v-divider class="mb-8" />
              <div>
                <div class="d-flex fill-width align-center justify-space-between">
                  SERVICES
                </div>
                <v-divider class="mb-4 mt-2" />
                <div v-if="!cart.filter(r => parseInt(r.product_is_service)).length" class="pb-4 caption red--text">
                  No Service Item
                </div>
                <div v-for="(i, iI) in cart.filter(r => parseInt(r.product_is_service))" :key="'item-service-' + iI" class="d-flex fill-width justify-space-between mb-4 py-4 pl-4 pr-2 grey lighten-4">
                  <div class="d-flex fill-width">
                    <div>
                      <v-img
                        :src="$getImage(i.galleries)"
                        aspect-ratio="1"
                        class="grey lighten-2 d-inline-block"
                        style="width: 100px!important"
                      />
                    </div>
                    <div class="d-flex flex-column fill-width pl-4">
                      <div class="grey--text text-uppercase">
                        <v-icon class="mr-1">
                          mdi-barcode
                        </v-icon>
                        <span class="caption">
                          {{ i.sku }}
                        </span>
                      </div>
                      <div class="d-flex align-center font-weight-bold">
                        <v-icon small class="mr-1">
                          {{ parseInt(i.product_is_service) ? 'mdi-account-wrench-outline' : 'mdi-package' }}
                        </v-icon>
                        {{ i.product_fullname }}
                      </div>
                      <div v-if="parseInt(i.trx)" class="d-flex fill-width justify-space-between align-center">
                        <div v-if="parseInt(i.price_special)" class="d-flex align-center" :style="{ textDecoration: parseInt(i.trx_price_active_custom) ? 'line-through!important' : 'none' }">
                          {{ $price(i.price_special) }}
                          <v-icon small class="ml-1" color="yellow darken-1">
                            mdi-star
                          </v-icon>
                        </div>
                        <div v-else-if="parseInt(i.trx_price_active) < parseInt(i.trx_price)" :style="{ textDecoration: parseInt(i.trx_price_active_custom) ? 'line-through!important' : 'none' }" class="d-flex fill-width justify-space-between align-center body-2">
                          <div>
                            <span class="d-inline-block red--text mr-2 body-2" style="text-decoration: line-through">
                              {{ $price(i.trx_price) }}
                            </span>
                            <span class="d-inline-block body-2">
                              {{ $price(i.trx_price_active) }}
                            </span>
                          </div>
                        </div>
                        <div v-else :style="{ textDecoration: parseInt(i.trx_price_active_custom) ? 'line-through!important' : 'none' }" class="d-flex fill-width justify-space-between align-center body-2 pr-2">
                          {{ $price(i.trx_price) }}
                        </div>
                        <div>
                          <v-text-field
                            :ref="'customCartPrice' + i.product_spec"
                            :value="parseInt(i.trx_price_active_custom) || 0"
                            :class="'customCartPrice' + i.product_spec"
                            :disabled="parseInt(dialogTrx.data.status) >= 3"
                            outlined
                            dense
                            hide-details
                            label="Custom Price (per-item)"
                            prefix="Rp"
                            append-icon="mdi-refresh"
                            @keypress="$NumberOnly"
                            @change="UpdatePriceCustom(i.product_spec)"
                            @click:append="UpdatePriceCustom(i.product_spec, true)"
                          />
                        </div>
                      </div>
                      <div v-else class="d-flex fill-width justify-space-between align-center">
                        <div v-if="parseInt(i.price_special)" class="d-flex align-center" :style="{ textDecoration: parseInt(i.trx_price_active_custom) ? 'line-through!important' : 'none' }">
                          {{ $price(i.price_special) }}
                          <v-icon small class="ml-1" color="yellow darken-1">
                            mdi-star
                          </v-icon>
                        </div>
                        <div v-else-if="parseInt(i.priceActive) < parseInt(i.price)" class="d-flex fill-width justify-space-between align-center body-2">
                          <div :style="{ textDecoration: parseInt(i.trx_price_active_custom) ? 'line-through!important' : 'none' }">
                            <span class="d-inline-block red--text mr-2 body-2" style="text-decoration: line-through">
                              {{ $price(i.price) }}
                            </span>
                            <span class="d-inline-block body-2">
                              {{ $price(i.priceActive) }}
                            </span>
                          </div>
                        </div>
                        <div v-else :style="{ textDecoration: parseInt(i.trx_price_active_custom) ? 'line-through!important' : 'none' }" class="d-flex fill-width justify-space-between align-center body-2">
                          {{ $price(i.price) }}
                        </div>
                        <div>
                          <v-text-field
                            :ref="'customCartPrice' + i.product_spec"
                            :value="parseInt(i.trx_price_active_custom) || 0"
                            :class="'customCartPrice' + i.product_spec"
                            :disabled="parseInt(dialogTrx.data.status) >= 3"
                            outlined
                            dense
                            hide-details
                            label="Custom Price (per-item)"
                            prefix="Rp"
                            append-icon="mdi-refresh"
                            @keypress="$NumberOnly"
                            @change="UpdatePriceCustom(i.product_spec)"
                            @click:append="UpdatePriceCustom(i.product_spec, true)"
                          />
                        </div>
                      </div>
                      <!-- <div class="d-flex fill-width justify-space-between py-1 body-2 font-weight-bold">
                        Catatan : {{ i.notes || '-' }}
                      </div> -->
                      <div class="d-flex fill-width justify-end py-1">
                        <div class="pr-2">
                          Price Subtotal :
                        </div>
                        <div v-if="parseInt(i.trx_price_active_custom)" :class="[parseInt(i.trx) ? 'pr-2' : '']" class="d-flex align-center font-weight-bold">
                          {{ $price(parseInt(i.trx_price_active_custom) * parseInt(i.quantity)) }}
                        </div>
                        <div v-else :class="[parseInt(i.trx) ? 'pr-2' : '']" class="d-flex align-center font-weight-bold">
                          {{ parseInt(i.trx) ? $price(i.trx_price_sum) : $price(parseInt(i.priceActive) * parseInt(i.quantity)) }}
                        </div>
                      </div>
                      <div v-if="parseInt(dialogTrx.data.status) >= 1" class="d-flex flex-column fill-width">
                        <div class="d-flex align-center grey--text pb-2 pt-1">
                          <v-chip v-if="parseInt(dialogTrx.data.status) < 3" small color="primary" @click.prevent="UploadImage(i.id)">
                            + Upload Report
                          </v-chip>
                          <span v-else>
                            Uploaded Reports
                          </span>
                        </div>
                        <div class="d-flex flex-wrap fill-width">
                          <a v-for="(ir, iRe) in $getImageDataAll(i.imgs)" :key="'img-report-' + i.product_spec + '-' + iRe" :href="ir.img" target="_blank">
                            <v-img
                              :src="ir.img"
                              aspect-ratio="1"
                              class="grey lighten-2 rounded-lg mr-2"
                              style="width: 75px!important;max-width: 75px!important"
                            />
                          </a>
                        </div>
                      </div>
                      <template v-if="i.trx_price_option">
                        <div v-if="i.trx_price_option.type === 'gift' && i.trx_price_option.gift" class="d-flex align-center caption orange--text text--darken-1">
                          <v-icon small class="mr-1" color="orange darken-1">
                            mdi-wallet-giftcard
                          </v-icon>
                          Free Gift : {{ i.trx_price_option.gift }}
                        </div>
                      </template>
                    </div>
                  </div>
                  <div>
                    <v-btn v-if="!parseInt(i.trx)" icon color="red" small @click.prevent="cart.splice(iI, 1)">
                      <v-icon>
                        mdi-close
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
                <div class="d-flex fill-width justify-end orange--text">
                  Service Subtotal : {{ $price($total(cart.filter(r => parseInt(r.product_is_service)), parseInt(dialogTrx.data.status) === 3)) }}
                </div>
                <div class="d-flex fill-width align-center justify-space-between pt-4">
                  PARTS
                </div>
                <v-divider class="mb-4 mt-2" />
                <div v-if="!cart.filter(r => !parseInt(r.product_is_service)).length" class="pb-4 caption red--text">
                  No Part Item
                </div>
                <div v-for="(i, iI) in cart.filter(r => !parseInt(r.product_is_service))" :key="'item-part-' + iI" class="d-flex fill-width justify-space-between mb-4 py-4 pl-4 pr-2 grey lighten-4">
                  <div class="d-flex fill-width">
                    <div>
                      <v-img
                        :src="$getImage(i.galleries)"
                        aspect-ratio="1"
                        class="grey lighten-2 d-inline-block"
                        style="width: 100px!important"
                      />
                    </div>
                    <div class="d-flex flex-column fill-width pl-4">
                      <div class="grey--text text-uppercase">
                        <v-icon class="mr-1">
                          mdi-barcode
                        </v-icon>
                        <span class="caption">
                          {{ i.sku }}
                        </span>
                      </div>
                      <!-- {{ i.priceActiveData || i.trx_price_option }} -->
                      <div class="d-flex align-center font-weight-bold">
                        <v-icon small class="mr-1">
                          {{ parseInt(i.product_is_service) ? 'mdi-account-wrench-outline' : 'mdi-package' }}
                        </v-icon>
                        {{ i.product_fullname }}, x {{ i.quantity }}
                      </div>
                      <div v-if="parseInt(i.trx)" class="d-flex fill-width justify-space-between align-center">
                        <div v-if="parseInt(i.price_special)" class="d-flex align-center" :style="{ textDecoration: parseInt(i.trx_price_active_custom) ? 'line-through!important' : 'none' }">
                          {{ $price(i.price_special) }}
                          <v-icon small class="ml-1" color="yellow darken-1">
                            mdi-star
                          </v-icon>
                        </div>
                        <div v-else-if="parseInt(i.trx_price_active) < parseInt(i.trx_price)" :style="{ textDecoration: parseInt(i.trx_price_active_custom) ? 'line-through!important' : 'none' }" class="d-flex fill-width justify-space-between align-center body-2">
                          <div>
                            <span class="d-inline-block red--text mr-2 body-2" style="text-decoration: line-through">
                              {{ $price(i.trx_price) }}
                            </span>
                            <span class="d-inline-block body-2">
                              {{ $price(i.trx_price_active) }}
                            </span>
                          </div>
                        </div>
                        <div v-else :style="{ textDecoration: parseInt(i.trx_price_active_custom) ? 'line-through!important' : 'none' }" class="d-flex fill-width justify-space-between align-center body-2 pr-2">
                          {{ $price(i.trx_price) }}
                        </div>
                        <div>
                          <v-text-field
                            :ref="'customCartPrice' + i.product_spec"
                            :value="parseInt(i.trx_price_active_custom) || 0"
                            :class="'customCartPrice' + i.product_spec"
                            :disabled="parseInt(dialogTrx.data.status) >= 3"
                            outlined
                            dense
                            hide-details
                            label="Custom Price (per-item)"
                            prefix="Rp"
                            append-icon="mdi-refresh"
                            @keypress="$NumberOnly"
                            @change="UpdatePriceCustom(i.product_spec)"
                            @click:append="UpdatePriceCustom(i.product_spec, true)"
                          />
                        </div>
                      </div>
                      <div v-else class="d-flex fill-width justify-space-between align-center">
                        <div v-if="parseInt(i.price_special)" class="d-flex align-center" :style="{ textDecoration: parseInt(i.trx_price_active_custom) ? 'line-through!important' : 'none' }">
                          {{ $price(i.price_special) }}
                          <v-icon small class="ml-1" color="yellow darken-1">
                            mdi-star
                          </v-icon>
                        </div>
                        <div v-else-if="parseInt(i.priceActive) < parseInt(i.price)" :style="{ textDecoration: parseInt(i.trx_price_active_custom) ? 'line-through!important' : 'none' }" class="d-flex fill-width justify-space-between align-center body-2">
                          <div>
                            <span class="d-inline-block red--text mr-2 body-2" style="text-decoration: line-through">
                              {{ $price(i.price) }}
                            </span>
                            <span class="d-inline-block body-2">
                              {{ $price(i.priceActive) }}
                            </span>
                          </div>
                        </div>
                        <div v-else :style="{ textDecoration: parseInt(i.trx_price_active_custom) ? 'line-through!important' : 'none' }" class="d-flex fill-width justify-space-between align-center body-2">
                          {{ $price(i.price) }}
                        </div>
                        <div>
                          <v-text-field
                            :ref="'customCartPrice' + i.product_spec"
                            :value="parseInt(i.trx_price_active_custom) || 0"
                            :class="'customCartPrice' + i.product_spec"
                            :disabled="parseInt(dialogTrx.data.status) >= 3"
                            outlined
                            dense
                            hide-details
                            label="Custom Price (per-item)"
                            prefix="Rp"
                            append-icon="mdi-refresh"
                            @keypress="$NumberOnly"
                            @change="UpdatePriceCustom(i.product_spec)"
                            @click:append="UpdatePriceCustom(i.product_spec, true)"
                          />
                        </div>
                      </div>
                      <!-- <div class="d-flex fill-width justify-space-between py-1 body-2 font-weight-bold">
                        Catatan : {{ i.notes || '-' }}
                      </div> -->
                      <div class="d-flex fill-width justify-end pt-2">
                        Price Subtotal :
                        <div class="d-flex align-center px-2">
                          <v-btn icon x-small class="rounded-0" @click.prevent="CART_QUANTITY('min', iI)">
                            <v-icon>
                              mdi-minus-circle
                            </v-icon>
                          </v-btn>
                          <input
                            v-model.number="cart[iI].quantity"
                            :disabled="parseInt(i.trx) ? true : false"
                            type="text"
                            class="mx-1 d-block grey lighten-2 text-center caption"
                            style="max-width: 45px;outline: none;"
                            @keypress="$NumOnly"
                            @keyup="!parseInt(cart[iI].quantity) ? (cart[iI].quantity = 0) : true"
                            @blur="!parseInt(cart[iI].quantity) ? (cart[iI].quantity = 0) : true"
                          >
                          <v-btn icon x-small class="rounded-0" @click.prevent="CART_QUANTITY('plus', iI)">
                            <v-icon>
                              mdi-plus-circle
                            </v-icon>
                          </v-btn>
                        </div>
                        <div v-if="parseInt(i.trx_price_active_custom)" :class="[parseInt(i.trx) ? 'pr-2' : '']" class="d-flex align-center font-weight-bold">
                          {{ $price(parseInt(i.trx_price_active_custom) * parseInt(i.quantity)) }}
                        </div>
                        <div v-else-if="parseInt(i.price_special)" :class="[parseInt(i.trx) ? 'pr-2' : '']" class="d-flex align-center font-weight-bold">
                          {{ $price(parseInt(i.price_special) * parseInt(i.quantity)) }}
                        </div>
                        <div v-else :class="[parseInt(i.trx) ? 'pr-2' : '']" class="d-flex align-center font-weight-bold">
                          {{ parseInt(i.trx) ? $price(i.trx_price_sum) : $price(parseInt(i.priceActive) * parseInt(i.quantity)) }}
                        </div>
                      </div>
                      <div v-if="parseInt(dialogTrx.data.status) >= 1" class="d-flex flex-column fill-width">
                        <div class="d-flex align-center grey--text pb-2 pt-1">
                          <v-chip v-if="parseInt(dialogTrx.data.status) < 3" small color="primary" @click.prevent="UploadImage(i.id)">
                            + Upload Report
                          </v-chip>
                          <span v-else>
                            Uploaded Reports
                          </span>
                        </div>
                        <div class="d-flex flex-wrap fill-width">
                          <a v-for="(ir, iRe) in $getImageDataAll(i.imgs)" :key="'img-report-part-' + i.product_spec + '-' + iRe" :href="ir.img" target="_blank">
                            <v-img
                              :src="ir.img"
                              aspect-ratio="1"
                              class="grey lighten-2 rounded-lg mr-2"
                              style="width: 75px!important;max-width: 75px!important"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <v-btn v-if="!parseInt(i.trx)" icon color="red" small @click.prevent="cart.splice(iI, 1)">
                      <v-icon>
                        mdi-close
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
                <div class="d-flex fill-width justify-end orange--text pb-2">
                  Parts Subtotal : {{ $price($total(cart.filter(r => !parseInt(r.product_is_service)), parseInt(dialogTrx.data.status) === 3)) }}
                </div>
                <div class="d-flex fill-width align-center justify-space-between pt-4">
                  PARTS ADDITIONAL
                  <v-btn v-if="parseInt(dialogTrx.data.status) < 3" small color="primary" depressed class="rounded-pill text-capitalize" dark @click.prevent="cartAdditional.push({ name: '', price: 0, price_buy: 0, quantity: 1, imgs: [] })">
                    <v-icon small ckass="mr-1">
                      mdi-plus
                    </v-icon>
                    Add
                  </v-btn>
                </div>
                <v-divider class="mb-4 mt-2" />
                <div v-if="!cartAdditional.length" class="pb-4 caption red--text">
                  No Additional Parts
                </div>
                <div v-else class="pb-4 caption red--text">
                  <div v-for="(ca, iCa) in cartAdditional" :key="'cart-additional' + iCa" class="py-2">
                    <div class="d-flex fill-width align-center justify-space-between">
                      <span class="black--text mr-1 body-1">{{ iCa + 1 }}.</span>
                      <v-text-field
                        v-model="cartAdditional[iCa].name"
                        :disabled="parseInt(dialogTrx.data.status) >= 3"
                        outlined
                        dense
                        hide-details
                        class="d-inline-block"
                        label="Name"
                      />
                      <div class="d-flex align-center pl-4" style="max-width: 65%;">
                        <v-text-field
                          v-model.number="cartAdditional[iCa].price_buy"
                          :disabled="parseInt(dialogTrx.data.status) >= 3"
                          outlined
                          dense
                          hide-details
                          prefix="Rp"
                          label="@Buy"
                          class="d-inline-block mr-1"
                          @keypress="$NumberOnly"
                        />
                        <v-text-field
                          v-model.number="cartAdditional[iCa].price"
                          :disabled="parseInt(dialogTrx.data.status) >= 3"
                          outlined
                          dense
                          hide-details
                          prefix="Rp"
                          label="@Sell"
                          class="d-inline-block ml-1"
                          @keypress="$NumberOnly"
                        />
                        <input
                          v-model.lazy="cartAdditional[iCa].quantity"
                          type="number"
                          class="body-1 d-inline-flex align-center justify-start ml-2 grey lighten-4 rounded"
                          style="display: inline-flex;padding: 7px;max-width:75px;outline:none;border:solid 2px #111;"
                          @keyup="!parseInt(cartAdditional[iCa].quantity) ? cartAdditional[iCa].quantity = 1 : ''"
                          @blur="!parseInt(cartAdditional[iCa].quantity) ? cartAdditional[iCa].quantity = 1 : ''"
                          @keypress="$NumberOnly"
                        >
                        <v-icon v-if="parseInt(dialogTrx.data.status) < 3" small color="red" class="mx-2" @click.prevent="CartAdditionalDelete(iCa)">
                          mdi-close
                        </v-icon>
                      </div>
                    </div>
                    <div v-if="parseInt(dialogTrx.data.status) >= 1" class="d-flex flex-column fill-width">
                      <div class="d-flex align-center grey--text pb-2 pt-1">
                        <v-chip v-if="parseInt(dialogTrx.data.status) < 3" small color="primary" @click.prevent="UploadImage(null, iCa)">
                          + Upload Report
                        </v-chip>
                        <span v-else>
                          Uploaded Reports
                        </span>
                      </div>
                      <div class="d-flex flex-wrap fill-width">
                        <a v-for="(ir, iRe) in $getImageDataAll(ca.imgs)" :key="'img-report-additional-' + iCa + '-' + iRe" :href="ir.img" target="_blank">
                          <v-img
                            :src="ir.img"
                            aspect-ratio="1"
                            class="grey lighten-2 rounded-lg mr-2"
                            style="width: 45px!important;max-width: 45px!important"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex fill-width justify-end orange--text">
                  Additional Subtotal : {{ $price(TotalAdditional) }}
                </div>
                <div class="d-flex flex-column align-end fill-width pt-8 pb-4">
                  <div class="d-flex align-center">
                    <div class="d-flex align-center gont-weight-bold mr-4">
                      <span class="subtitle-1 gont-weight-bold">SUBTOTAL :</span>
                    </div>
                    <div class="subtitle-1 gont-weight-bold">
                      {{ $price(Subtotal) }}
                    </div>
                  </div>
                  <div class="d-flex align-center">
                    <div class="d-flex align-center gont-weight-bold mr-4">
                      <span class="subtitle-1">PPN (11%) :</span>
                    </div>
                    <div class="subtitle-1 green--text gont-weight-bold">
                      +{{ $price(Ppn) }}
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-end fill-width mb-4 pt-0 pb-4">
                  <div class="d-flex align-center orange darken-2 white--text px-6 py-4">
                    <div class="d-flex align-center font-weight-black mr-4">
                      <v-icon medium class="mr-2" color="white">
                        mdi-cash-multiple
                      </v-icon>
                      <span class="text-sm-h6">TOTAL :</span>
                    </div>
                    <div class="text-h5 font-weight-black">
                      {{ $price(TotalAmount) }}
                    </div>
                  </div>
                </div>
              </div>
              <v-divider v-if="parseInt(dialogTrx.data.id) && (TotalAmount > 0)" class="my-4" />
              <div v-if="parseInt(dialogTrx.data.id) && parseInt(dialogTrx.data.status) >= 2">
                <div v-if="parseInt(dialogTrx.data.id) && (TotalAmount > 0)" class="d-flex align-center fill-width justify-space-between">
                  <div class="d-flex align-center">
                    <v-icon class="mr-2">
                      mdi-cash-register
                    </v-icon>
                    Payment List
                  </div>
                  <v-btn v-if="(TotalAmount - parseInt(dialogTrx.data.paid)) > 0" small dark depressed color="indigo text-capitalize rounded-pill" @click.prevent="TRX_PAYMENT_SHOW()">
                    <v-icon class="mr-1" small>
                      mdi-plus
                    </v-icon>
                    Add Payment
                  </v-btn>
                </div>
                <v-divider v-if="parseInt(dialogTrx.data.id) && (TotalAmount > 0)" class="mt-4" />
                <v-row v-if="parseInt(dialogTrx.data.id)" no-gutters class="py-2">
                  <v-col v-if="!trxPayments.length" cols="12" class="d-flex align-center justify-center pa-4 caption-small grey--text">
                    No payment data
                  </v-col>
                  <v-col v-for="(payment, iPayment) in trxPayments" :key="'payment-history-' + iPayment" cols="12" class="d-flex align-center justify-space-between pa-2 lime lighten-4 my-1">
                    <div class="d-flex align-center">
                      <v-chip class="mr-2 font-weight-bold" small>
                        #{{ iPayment + 1 }}
                      </v-chip>
                      <div class="d-flex flex-column">
                        <div class="caption-small grey--text text--darken-2">
                          {{ $localDT(payment.created, 'datelocal') }}
                        </div>
                        <div class="text-truncate caption" style="max-width:200px;">
                          <strong>{{ $price(payment.amount) }}</strong> : {{ XenditPaymentParse(payment).status ? XenditPaymentParse(payment).notes : payment.notes }}
                        </div>
                      </div>
                    </div>
                    <div class="d-flex align-center">
                      <a v-if="XenditPaymentParse(payment).status" :href="XenditPaymentParse(payment).invoice_url" target="_blank" class="mr-2 text-capitalize pink rounded-pill white--text caption-small px-2">
                        #INV
                      </a>
                      <v-chip x-small color="orange" dark class="mr-1 text-capitalize">
                        {{ payment.payment_type }}
                      </v-chip>
                      <v-chip x-small :color="parseInt(payment.status) ? 'green darken-2' : 'red darken-2'" dark class="ml-1" @click.prevent="TRX_PAYMENT_SHOW(payment)">
                        {{ parseInt(payment.status) ? 'Paid' : 'Unpaid' }}
                      </v-chip>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div v-if="parseInt(dialogTrx.data.status) >= 2">
                <div v-if="parseInt(dialogTrx.data.id)" class="d-flex align-center pt-4">
                  <v-icon class="mr-2">
                    mdi-cash
                  </v-icon>
                  Payment
                </div>
                <v-divider class="mt-0 mb-6" />
                <div class="d-flex flex-column align-end fill-width mb-4 pt-0 pb-4">
                  <div class="d-flex align-center purple darken-2 white--text px-6 py-2 mb-2">
                    <div class="d-flex align-center font-weight-black mr-4">
                      <v-icon medium class="mr-2" color="white">
                        mdi-cash-check
                      </v-icon>
                      <span class="text-sm-h8">DIBAYAR :</span>
                    </div>
                    <div class="text-h8 font-weight-black">
                      {{ $price(dialogTrx.data.paid) }}
                    </div>
                  </div>
                  <div class="d-flex align-center indigo darken-2 white--text px-6 py-2">
                    <div class="d-flex align-center font-weight-black mr-4">
                      <v-icon medium class="mr-2" color="white">
                        mdi-cash-fast
                      </v-icon>
                      <span class="text-sm-h8">SISA :</span>
                    </div>
                    <div class="text-h8 font-weight-black">
                      {{ $price(TotalAmount - parseInt(dialogTrx.data.paid)) }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="parseInt(dialogTrx.data.status) < 3" class="grey lighten-3 pa-4">
                <v-btn v-if="$role(['service_advisor', 'head_operation'], $store.state.user.role)" :disabled="!customer.selected || !dialogVehicle.selected || (!TotalAmount || loadingProcess)" depressed color="blue" large dark block class="rounded-0 text-capitalize" @click.prevent="$role(['service_advisor', 'head_operation'], $store.state.user.role) ? TRX_PROCESS() : false">
                  <v-icon class="mr-2">
                    mdi-content-save-outline
                  </v-icon>
                  {{ TRXEDITED ? 'PROSES ORDER' : 'UPDATE ORDER' }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="newCustomer.show"
      persistent
      scrollable
      max-width="290"
    >
      <v-card>
        <v-card-title class="body-1 justify-center">
          Create New Customer
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4" style="max-height: 300px;">
          <v-text-field
            v-model="newCustomer.data.name"
            outlined
            dense
            hide-details
            label="Name"
            class="mb-4"
          />
          <v-text-field
            v-model="newCustomer.data.email"
            outlined
            dense
            hide-details
            label="Email"
            class="mb-4 rounded-0"
          />
          <v-text-field
            v-model="newCustomer.data.phone"
            outlined
            dense
            hide-details
            label="Phone"
            class="mb-4 rounded-0"
            @keypress="$NumOnly"
          />
          <v-select
            v-model.number="newCustomer.data.type"
            :items="[{ name: 'Regular Customer', value: 0 }, { name: 'Business', value: 1 }]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="rounded-0"
            label="Customer Type"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="newCustomer.show = false"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="loadingProcess"
            color="green darken-1"
            text
            @click="ProcessCustomer()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogUpdateTrx.show"
      persistent
      scrollable
      max-width="320"
    >
      <v-card v-if="dialogTrx.data">
        <v-card-title class="body-1 justify-center">
          Update Status {{ ('#RDMS/' + parseInt(dialogTrx.data.store || 0) + '/TRX/' + parseInt(dialogTrx.data.id)) }}
        </v-card-title>
        <v-divider />
        <v-card-text class="py-8" style="max-height: 300px;">
          <v-select
            v-model.number="dialogUpdateTrx.status"
            :items="$store.state.user.role === 'director' && (TotalAmount - parseInt(dialogTrx.data.paid)) > 0 ? [{ name: 'SA Verification', value: 2 }, { name: (((TotalAmount - parseInt(dialogTrx.data.paid)) > 0) ? 'Waiting Payment' : 'Completed'), value: 3 }] : [{ name: 'Waiting Confirmation', value: 0 }, { name: 'Processed', value: 1 }, { name: 'SA Verification', value: 2 }, { name: (((TotalAmount - parseInt(dialogTrx.data.paid)) > 0) ? 'Waiting Payment' : 'Completed'), value: 3 }, { name: 'Canceled', value: 99 }]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="rounded-0"
            label="Order Status"
          />
          <div v-if="parseInt(dialogUpdateTrx.status) === 3 && (TotalAmount - parseInt(dialogTrx.data.paid)) > 0" class="px-4 pt-4 pb-0 text-center red--text">
            Payment has not been paid!
          </div>
          <!-- <div v-if="!ServiceInfoIsClear()" class="px-4 pt-4 pb-0 text-center red--text">
            Please input service Information!
          </div> -->
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="red darken-1"
            text
            class="text-capitalize"
            @click="dialogUpdateTrx.show = false, ClearTrx()"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            :disabled="loadingProcess"
            color="blue darken-1"
            depressed
            small
            dark
            class="text-capitalize rounded-0"
            @click="ProcessUpdateTrx()"
          >
            Update Status
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogStruk.show"
      width="750"
      class="xxx"
      content-class="dialog-printed black--text"
    >
      <v-card v-if="dialogStruk.show">
        <v-card-text class="pb-4 caption black--text" style="letter-spacing:0px!important;">
          <div>
            <div class="d-flex fill-width justify-end mb-2" style="overflow: hidden;height: 100px;">
              <img src="/icon-clean.png" style="height: 155px;margin-top: -20px;">
            </div>
            <v-divider style="margin-bottom: 1px;z-index:1;" />
            <v-divider style="z-index:1;" />
            <div v-if="customer.selected" class="black--text">
              <div class="pt-2 text-capitalize">
                <div class="d-flex fill-width align-center justify-space-between font-weight-bold">
                  <div class="d-flex align-center">
                    {{ customer.selected.name }}
                    <v-chip v-if="parseInt(dialogTrx.data.customer_type)" x-small class="ml-2">
                      Business
                    </v-chip>
                  </div>
                  <span class="font-weight-bold">
                    # RDMS/CUST/{{ customer.selected.id }}
                  </span>
                </div>
                <div>
                  {{ dialogTrx.data.shipping_address || '-' }}
                </div>
              </div>
              <div class="d-flex justify-space-between fill-width">
                <div class="d-flex align-center">
                  <v-icon x-small class="mr-1">
                    mdi-cellphone
                  </v-icon>
                  {{ customer.selected.phone }}
                </div>
                <div class="d-flex align-center">
                  <v-icon x-small class="mr-1">
                    mdi-email-outline
                  </v-icon>
                  {{ customer.selected.email }}
                </div>
              </div>
            </div>
            <v-divider class="my-2" />
            <div v-if="parseInt(dialogTrx.data.status) < 3" class="pt-2 font-weight-bold title d-flex justify-center text-uppercase">
              {{ 'ESTIMASI SEVICE' }}
            </div>
            <div v-else class="font-weight-bold title d-flex justify-center text-uppercase">
              {{ 'INVOICE' }}
            </div>
          </div>
          <div class="d-flex flex-column fill-width align-center justify-center">
            <barcode :value="GENERATED_ID" :display-value="false" :width="2.3" :height="45" class="barcode-class">
              ERROR
            </barcode>
            <div class="d-flex fill-width subtitle-2 justify-center font-weight-bold">
              RDMS/{{ dialogTrx.data.store + '/' + GENERATED_ID }}
            </div>
          </div>
          <v-divider class="my-2" />
          <div v-if="dialogVehicle.selected" class="black--text">
            <div class="d-flex fill-width align-start justify-space-between">
              <div class="d-flex flex-column justify-center text-uppercase pr-1" style="max-width: 50%!important;">
                <span class="fill-width">
                  <span class="font-weight-bold d-inline-block" style="min-width: 70px;">DC / Lokasi</span>: {{ UnitLocation || '-' }}
                </span>
                <span class="fill-width">
                  <span class="font-weight-bold d-inline-block" style="min-width: 70px;">No. Polisi</span>: {{ dialogVehicle.selected.vehicle_plat }}
                </span>
                <span class="fill-width">
                  <span class="font-weight-bold d-inline-block" style="min-width: 70px;">Jenis</span>: {{ dialogVehicle.selected.vehicle_brand }} {{ dialogVehicle.selected.vehicle_type }} {{ dialogVehicle.selected.vehicle_year }}
                </span>
                <span class="fill-width">
                  <span class="font-weight-bold d-inline-block" style="min-width: 70px;">KM</span>: {{ dialogTrx.data.service_km || 0 }}
                </span>
                <span class="fill-width">
                  <span class="font-weight-bold d-inline-block" style="min-width: 70px;">Keluhan</span>: {{ dialogTrx.data.notes || '-' }}
                </span>
              </div>
              <div class="d-flex flex-column justify-center text-uppercase pl-1" style="max-width: 50%!important;">
                <span class="fill-width">
                  <span class="font-weight-bold d-inline-block pr-1" style="min-width: 78px;">Tgl</span>: {{ $localDT(dialogTrx.data.created, 'datelocal') }}
                  <!-- <span class="font-weight-bold d-inline-block pr-1" style="min-width: 78px;">Tgl</span>: {{ dialogTrx.data.service_date || '-' }} -->
                </span>
                <!-- <span class="fill-width">
                  <span class="font-weight-bold d-inline-block pr-1" style="min-width: 78px;">No Keluhan</span>: -
                </span> -->
              </div>
            </div>
            <!-- <div class="d-flex fill-width">
              <div class="d-flex align-center">
                <v-icon x-small class="mr-1">
                  mdi-code-not-equal
                </v-icon>
                {{ dialogTrx.data.notes || '-' }}
              </div>
            </div> -->
          </div>
          <v-divider class="my-2" />
          <!-- <span class="grey--text">Order Items :</span> -->
          <table class="fill-width table-print">
            <thead>
              <tr class="font-weight-bold">
                <td class="text-center pa-1">No</td>
                <td class="text-center pa-1">Item</td>
                <td class="text-center pa-1">Qty.</td>
                <td class="text-center pa-1">Harga</td>
                <td class="text-center pa-1">Disc.</td>
                <td class="text-center pa-1">Harga Stlh.</td>
                <td class="text-center pa-1">Jumlah</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="pa-1 text-decoration-underline caption" colspan="7">
                  SPAREPART (SUKU CADANG)
                  <!-- <v-divider /> -->
                </td>
              </tr>
              <tr v-if="!cart.filter(r => !parseInt(r.product_is_service)).length">
                <td class="pa-1 caption grey--text" colspan="7">
                  No item
                </td>
              </tr>
              <tr v-for="(i, iI) in cart.filter(r => !parseInt(r.product_is_service))" :key="'item-struk-part-' + iI">
                <td class="pa-1 text-center">{{ iI + 1 }}</td>
                <td class="pa-1 text-center">{{ i.product_fullname }}</td>
                <td class="pa-1 text-right">{{ parseInt(i.quantity) }}</td>
                <td class="pa-1 text-right">{{ $price(i.trx_price) }}</td>
                <td class="pa-1 text-right">{{ $price((parseInt(i.trx_price) || 0) - (parseInt(i.trx_price_active_custom) || parseInt(i.price_special) || (parseInt(i.trx_price) || 0) || 0)) }}</td>
                <td class="pa-1 text-right">{{ $price(parseInt(i.trx_price_active_custom) || parseInt(i.price_special) || parseInt(i.trx_price) || 0) }}</td>
                <td class="pa-1 text-right">{{ $price((parseInt(i.trx_price_active_custom) || parseInt(i.price_special) || parseInt(i.trx_price) || 0) * parseInt(i.quantity)) }}</td>
              </tr>
              <tr v-if="cart.filter(r => !parseInt(r.product_is_service)).length">
                <td class="pa-1 caption grey--text font-weight-bold" colspan="6">
                  Sub Total
                </td>
                <td class="pa-1 text-right">
                  {{ $price($total(cart.filter(r => !parseInt(r.product_is_service)), parseInt(dialogTrx.data.status) === 3)) }}
                </td>
              </tr>
              <tr>
                <td class="pa-1 text-decoration-underline caption" colspan="7">
                  SERVICE (JASA)
                  <!-- <v-divider /> -->
                </td>
              </tr>
              <tr v-if="!cart.filter(r => parseInt(r.product_is_service)).length">
                <td class="pa-1 caption grey--text" colspan="7">
                  No item
                </td>
              </tr>
              <tr v-for="(i, iI) in cart.filter(r => parseInt(r.product_is_service))" :key="'item-struk-service-' + iI">
                <td class="pa-1 text-center">{{ iI + 1 }}</td>
                <td class="pa-1 text-center">{{ i.product_fullname }}</td>
                <td class="pa-1 text-right">{{ parseInt(i.quantity) }}</td>
                <td class="pa-1 text-right">{{ $price(i.trx_price) }}</td>
                <td class="pa-1 text-right">{{ $price((parseInt(i.trx_price) || 0) - (parseInt(i.trx_price_active_custom) || parseInt(i.price_special) || (parseInt(i.trx_price) || 0) || 0)) }}</td>
                <td class="pa-1 text-right">{{ $price(parseInt(i.trx_price_active_custom) || parseInt(i.price_special) || parseInt(i.trx_price) || 0) }}</td>
                <td class="pa-1 text-right">{{ $price((parseInt(i.trx_price_active_custom) || parseInt(i.price_special) || parseInt(i.trx_price) || 0) * parseInt(i.quantity)) }}</td>
              </tr>
              <tr v-if="cart.filter(r => parseInt(r.product_is_service)).length">
                <td class="pa-1 caption grey--text font-weight-bold" colspan="6">
                  Sub Total
                </td>
                <td class="pa-1 text-right">
                  {{ $price($total(cart.filter(r => parseInt(r.product_is_service)), parseInt(dialogTrx.data.status) === 3)) }}
                </td>
              </tr>
              <tr>
                <td class="pa-1 text-decoration-underline caption" colspan="7">
                  LAIN-LAIN
                  <!-- <v-divider /> -->
                </td>
              </tr>
              <tr v-if="!cartAdditional.length">
                <td class="pa-1 caption grey--text" colspan="7">
                  No item
                </td>
              </tr>
              <tr v-for="(i, iI) in cartAdditional" :key="'item-struk-additional-' + iI">
                <td class="pa-1 text-center">{{ iI + 1 }}</td>
                <td class="pa-1 text-center" colspan="5">{{ i.name }}</td>
                <td class="pa-1 text-right">{{ $price(TotalAdditional) }}</td>
              </tr>
              <tr v-if="TotalAdditional.length">
                <td class="pa-1 caption grey--text font-weight-bold" colspan="6">
                  Sub Total
                </td>
                <td class="pa-1 text-right">
                  {{ $price(TotalAdditional) }}
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <v-divider class="my-1" />
          <span class="grey--text">Notes :</span>
          <div class="black--text">
            {{ dialogTrx.data.notes || '-' }}
          </div> -->
          <!-- <v-divider class="mb-2 mt-1" /> -->
          <div class="align-right black--text px-2 pt-2">
            <div class="d-flex justify-space-between align-center fill-width">
              <div>
                DPP
              </div>
              <div>
                {{ $price(dialogTrx.data.amount_subtotal) }}
              </div>
            </div>
            <div class="d-flex justify-space-between align-center fill-width">
              <div>
                PPN (11%)
              </div>
              <div>
                +{{ $price(dialogTrx.data.amount_tax) }}
              </div>
            </div>
            <div v-if="dialogTrx.data.voucher_code && parseInt(dialogTrx.data.voucher_value)" class="d-flex justify-space-between align-center fill-width">
              <div>
                Voucher <span class="font-weight: normal;">{{ dialogTrx.data.voucher_code }}</span>
              </div>
              <div class="red--text">
                -{{ $price(dialogTrx.data.voucher_value) }}
              </div>
            </div>
            <v-divider class="my-2" />
            <div class="d-flex justify-space-between align-center fill-width">
              <div>
                TOTAL
              </div>
              <div class="font-weight-bold">
                {{ $price(dialogTrx.data.amount_total) }}
              </div>
            </div>
          </div>
        </v-card-text>
        <v-divider />
        <div class="d-flex justify-end fill-width py-8">
          <div class="text-center px-2" style="min-width: 200px;">
            Hormat Kami<br><br><br>
            ( ......................... )
          </div>
        </div>
        <v-divider class="no-print" />
        <v-card-actions class="no-print">
          <v-btn
            color="red"
            text
            class="text-capitalize"
            @click="dialogStruk.show = false"
          >
            Close
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            text
            dark
            class="text-capitalize"
            @click="PrintStruk()"
          >
            <v-icon small color="white" class="mr-1">
              mdi-print
            </v-icon>
            Print
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogAddress.show"
      persistent
      scrollable
      max-width="345"
    >
      <v-card v-if="dialogAddress.data">
        <v-card-title class="body-1 justify-center">
          {{ dialogAddress.data.id ? 'Update Address' : 'Create Address' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4" style="max-height: 75vh;">
          <v-text-field
            v-model="dialogAddress.data.name"
            outlined
            dense
            hide-details
            label="Nama Penerima"
            class="mb-4"
          />
          <v-text-field
            v-model="dialogAddress.data.phone"
            outlined
            dense
            hide-details
            label="No HP"
            class="mb-4"
            @keypress="$NumberOnly"
          />
          <v-textarea
            v-model="dialogAddress.data.address"
            outlined
            dense
            hide-details
            label="Alamat Lengkap"
            class="mb-4"
          />
          <v-autocomplete
            v-model.number="dialogAddress.data.kecamatan_id"
            :name="Math.random()"
            :search-input.sync="areaquery"
            :items="areas"
            item-value="kecamatan_id"
            item-text="area_name"
            outlined
            dense
            hide-details
            label="Kecamatan, Kota, Provinsi"
            class="mb-4"
            autocomplete="disabled"
            @change="areaSync()"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="orange darken-1"
            small
            dark
            text
            class="text-capitalize"
            @click="dialogAddress.show = false"
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="dialogAddress.data.id"
            color="red darken-1"
            small
            dark
            text
            class="text-capitalize caption ml-2"
            @click="AddressDelete(dialogAddress.data.id)"
          >
            Delete Address
          </v-btn>
          <v-spacer />
          <v-btn
            :disabled="loadingProcess"
            depressed
            small
            dark
            color="rounded-lg indigo darken-2"
            class="text-capitalize"
            @click="ProcessAddress()"
          >
            {{ dialogAddress.data.id ? 'Update' : 'Save' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogPayment.show"
      persistent
      scrollable
      max-width="290"
    >
      <v-card v-if="dialogPayment.data">
        <v-card-title class="body-1 justify-center">
          {{ parseInt(dialogPayment.data.id) ? 'Update Payment' : 'Create Payment' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4" style="max-height: 300px;">
          <v-select
            v-model="dialogPayment.data.payment_type"
            :items="$store.state.payment.filter(r => (
              (dialogPayment.data.payment_type !== 'online' && r.id === 'online' && parseInt(dialogPayment.data.id))
                ? false
                : r.customerRequired
                  ? (customer.selected
                      ? true
                      : false
                    )
                  : true
            ))"
            item-text="name"
            item-value="id"
            outlined
            dense
            hide-details
            class="mb-4"
            label="Payment Type"
          />
          <v-text-field
            v-model.number="dialogPayment.data.amount"
            outlined
            dense
            hide-details
            label="Amount"
            class="mb-4"
            @keypress="$NumberOnly"
          />
          <v-textarea
            v-if="parseInt(dialogPayment.data.id) && dialogPayment.data.payment_type === 'online'"
            :value="XenditPaymentParse(dialogPayment.data).notes"
            :disabled="true"
            outlined
            dense
            rows="2"
            hide-details
            label="Notes"
            class="mb-4"
          />
          <v-textarea
            v-else
            v-model="dialogPayment.data.notes"
            outlined
            dense
            rows="2"
            hide-details
            label="Notes"
            class="mb-4"
          />
          <v-select
            v-if="!(dialogPayment.data.payment_type === 'online' && !parseInt(dialogPayment.data.id))"
            v-model.number="dialogPayment.data.status"
            :items="[{ name: 'Unpaid', value: 0 }, { name: 'Paid', value: 1 }]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="mb-4"
            label="Payment Status"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="orange darken-1"
            small
            dark
            text
            class="text-capitalize"
            @click="dialogPayment.show = false"
          >
            Close
          </v-btn>
          <v-btn
            v-if="parseInt(dialogTrx.data.status) < 3 && parseInt(dialogPayment.data.id)"
            color="red darken-1"
            small
            dark
            text
            class="text-capitalize caption ml-2"
            @click="TRX_PAYMENT_DELETE(dialogPayment.data.id)"
          >
            Delete
          </v-btn>
          <v-spacer />
          <v-btn
            :disabled="loadingProcess"
            depressed
            small
            dark
            color="rounded-lg indigo darken-2"
            class="text-capitalize"
            @click="TRX_PAYMENT_PROCESS()"
          >
            {{ parseInt(dialogPayment.data.id) ? 'Update' : 'Save' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogVehicle.show"
      persistent
      scrollable
      max-width="375"
    >
      <v-card v-if="dialogVehicle.selected" >
        <v-card-title class="body-1 justify-center">
          {{ dialogVehicle.selected.id ? 'Update Vehicle' : 'Create New Vehicle' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4" style="max-height: 300px;">
          <v-autocomplete
            v-model="dialogVehicle.selected.vehicle_brand"
            :items="$store.state.product.categories"
            item-text="name"
            item-value="name"
            outlined
            dense
            hide-details
            label="Select Brand"
            class="mb-4"
            :disabled="!$role(['service_advisor', 'head_operation'], $store.state.user.role)"
          >
            <template v-slot:item="data">
              <template>
                <v-list-item-avatar>
                  <img :src="data.item.logo">
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name" />
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
          <v-text-field
            v-model="dialogVehicle.selected.vehicle_type"
            outlined
            dense
            hide-details
            label="Vehicle Type / Name"
            class="mb-4 rounded-0"
          />
          <v-text-field
            v-model="dialogVehicle.selected.vehicle_year"
            outlined
            dense
            hide-details
            label="Vehicle Production Year"
            class="mb-4 rounded-0"
            @keypress="$NumOnly"
          />
          <v-text-field
            v-model="dialogVehicle.selected.vehicle_plat"
            outlined
            dense
            hide-details
            label="Vehicle Plat Number"
            class="mb-4 rounded-0"
          />
          <v-textarea
            v-model="dialogVehicle.selected.notes"
            outlined
            dense
            hide-details
            label="Notes"
            class="mb-4 rounded-0"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="dialogVehicle.show = false, dialogVehicle.selected = null"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="VEHICLE_PROCESS()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VueBarcode from 'vue-barcode'
import { mapState } from 'vuex'
let searchInterval = null
let searchAreaTimeout = null
const newTrx = {
  id: 0,
  store: 0,
  store_users: [],
  platform: 0, // 0: offline store // 1: website
  customer: 0,
  cashier: 0,

  payment_type: 'bank_transfer', // online (xendit / payment gateway), bank_transfer, cod (driver), cash, card_debit, card_credit
  payment_description: '', // payment information
  payment_notes: '', // payment notes
  payment_status: 0, // 0: unpaid, 1: paid lunas, 2: paid hutang

  cash: 0, // cash uang tunai
  cash_refund: 0, // cash kembalian

  xendit_id: '', // xendit auto
  xendit_inv: '', // xendit auto
  xendit_ext: '', // xendit ext id (trx-trxid)
  weight: 0, // grams

  amount_subtotal: 0,
  amount_tax: 0,
  amount_shipping: 0,
  amount_total: 0,

  shipping_method: 'none', // none, driver or courier(driver, jne jnt, etc)
  shipping_method_courier: 'jne',
  shipping_method_type: '', // yes 1-2 days, etc
  shipping_awb: '', // yes 1-2 days, etc

  shipping_driver: 0,
  shipping_driver_lat: '',
  shipping_driver_lng: '',
  shipping_driver_status: 0, // 0: pickup, 1: shipping process, 2: complete, 99: pending, 100: cancel
  shipping_driver_notes: '',

  shipping_name: '',
  shipping_phone: '',
  shipping_address: '',
  shipping_postalcode: '',
  shipping_city: '',
  shipping_coordinate_lat: '',
  shipping_coordinate_lng: '',

  voucher: '',
  notes: '',
  created: '',
  status: 0,

  service_vehicle: 0,
  service_km: 0,
  service_date: null,
  service_time: null,
  service_km_next: 0,
  service_date_next: null,
  guarantee_km: 0,
  guarantee_date: null,
  guarantee_claim: 0, // Claimed Guarantee
  guarantee_claim_notes_customer: '',
  guarantee_claim_notes: '',
  items_additional: [],
  items_additional_price: 0
}
export default {
  name: 'Transaction',
  components: {
    barcode: VueBarcode
  },
  data: vm => ({
    storeUnit: 0,
    storeUsers: [],
    dialogStruk: {
      show: false
    },
    headers: [
      {
        text: 'ORDER ID',
        value: 'id'
      },
      { text: 'Vehicle', value: 'vehicle_plat' },
      { text: 'Customer', value: 'customer_name' },
      { text: 'Total', value: 'amount_total', align: 'right' },
      { text: 'Payment', value: 'payment_type' },
      { text: 'Paid', value: 'paid', align: 'right' },
      { text: 'Created', value: 'created' },
      { text: 'Status', value: 'status' },
      { text: 'Opsi', value: 'option', sortable: false }
    ],
    dates: [],
    menu: false,
    tableSearch: '',
    tableLoading: false,
    tableAchievement: 3,
    table: [],
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 50,
    tableSearchStatus: null,
    tableSearchPayment: '',
    options: {},
    dialogTrx: {
      show: false,
      storeActive: 0,
      data: null
    },
    cart: [],
    items: {
      query: '',
      result: [],
      selected: null
    },
    customer: {
      query: '',
      result: [],
      selected: null
    },
    customerAddress: [],
    customerAddressSelected: null,
    customerShippingList: [],
    customerShippingSelected: null,
    newCustomer: {
      show: false,
      data: {
        id: null,
        name: '',
        email: '',
        phone: '',
        type: 0
      }
    },
    dialogUpdateTrx: {
      show: false,
      shipping_awb: '',
      shipping_driver: 0,
      status: 0
    },
    drivers: [],
    tableSummary: {
      total_summary_normal: 0,
      total_summary: 0,
      tota: 0,
      total_summary_hpp: 0
    },
    dialogAddress: {
      show: false,
      data: null
    },
    areaquery: '',
    areas: [],
    trxPayments: [],
    dialogPayment: {
      show: false,
      data: null
    },
    loadingProcess: false,
    loadingImageUpload: false,
    newPoinUpdate: 0,
    dialogVehicle: {
      show: false,
      data: [],
      selected: null
    },
    cartAdditional: [],
    menuDateService: false,
    menuDateServiceNext: false,
    menuDateGuarantee: false
  }),
  computed: {
    ...mapState({
      storeActive: state => state.storeActive
    }),
    UnitLocation () {
      const idUnit = parseInt(this.dialogTrx.data.store) || 0
      const found = this.$store.state.storeData.find(r => idUnit)
      return found ? found.name || '-' : '-'
    },
    PoinUsed () {
      if (parseInt(this.newPoinUpdate)) {
        return parseInt(this.newPoinUpdate) * parseInt(this.dialogTrx.data.point_used_price_100 || 0)
      }
      if (this.dialogTrx.show && parseInt(this.dialogTrx.data.point_used) > 0) {
        return parseInt(this.dialogTrx.data.point_used) * parseInt(this.dialogTrx.data.point_used_price_100 || 0)
      }
      return 0
    },
    TRXEDITED () {
      if (this.dialogTrx.data) {
        if (this.dialogTrx.data.id) {
          return false
        }
      }
      return true
    },
    COUNT_ONGKIR () {
      const s = (this.customerShippingSelected ? parseInt(this.customerShippingSelected.cost_value) || 0 : 0)
      const cart = this.$CART_PARSE(this.cart)
      const weightCost = this.$SHIPPING_COST(cart.weight, s)
      if (this.customerShippingSelected) {
        return weightCost
      }
      return 0
    },
    GENERATED_ID () {
      return this.dialogTrx.data ? parseInt(this.dialogTrx.data.id) ? ('RDMS/' + parseInt(this.dialogTrx.data.id)) : null : null
    },
    dateRangeText () {
      return this.dates.join(' ~ ')
    },
    TotalAdditional () {
      let total = 0
      for (let a = 0; a < this.cartAdditional.length; a++) {
        const newSubtotal = (parseInt(this.cartAdditional[a].price) || 0) * (parseInt(this.cartAdditional[a].quantity) || 1)
        total += newSubtotal > 0 ? newSubtotal : 0
      }
      return total
    },
    Subtotal () {
      if (this.dialogTrx && this.dialogTrx.data) {
        return this.$total(this.cart, parseInt(this.dialogTrx.data.status) === 3) + this.TotalAdditional
      }
      return 0
    },
    Ppn () {
      if (this.dialogTrx && this.dialogTrx.data) {
        const subtotal = (this.Subtotal)
        return this.$ppn(subtotal)
      }
      return 0
    },
    TotalAmount () {
      return (this.Subtotal + this.Ppn)
    }
  },
  watch: {
    cart (v) {
      this.CART_SYNC()
    },
    'dialogTrx.data.payment_type' (v) {
      this.CART_SYNC()
    },
    areaquery (v) {
      if (!v) {
        return
      }
      const findSelectedArea = this.areas.find(r => r.area_name === v)
      if (findSelectedArea) {
        return
      }
      if (searchAreaTimeout) {
        clearTimeout(searchAreaTimeout)
      }
      searchAreaTimeout = setTimeout(() => {
        this.areaGet('?q=' + v)
        clearTimeout(searchAreaTimeout)
      }, 300)
    },
    // customerAddressSelected (v) {
    //   if (v && this.dialogTrx.data.shipping_method === 'courier') {
    //     this.SHIPPING_CHECK(parseInt(v.kecamatan_id))
    //   }
    // },
    'dialogTrx.data.shipping_method_courier' () {
      const v = this.customerAddressSelected
      if (v) {
        this.SHIPPING_CHECK(parseInt(v.kecamatan_id))
      }
    },
    'dialogTrx.data.shipping_method' (v) {
      this.customerShippingSelected = null
      this.customerShippingList = []
      if (v === 'courier' && this.customerAddressSelected && this.dialogTrx.data.shipping_method_courier === 'jne') {
        this.SHIPPING_CHECK(parseInt(this.customerAddressSelected.kecamatan_id))
      }
    },
    // dates (d) {
    //   if (d.length === 2) {
    //     this.TRX_GET(this.tableSearch)
    //   }
    // },
    options: {
      handler () {
        this.TRX_GET(this.tableSearch)
      },
      deep: true
    },
    tableSearch: function (q) {
      if (searchInterval) {
        clearTimeout(searchInterval)
      }
      searchInterval = setTimeout(() => {
        this.TRX_GET(q)
      }, 300)
    },
    storeUnit () {
      this.TRX_GET(this.tableSearch)
    },
    tableSearchPayment () {
      this.TRX_GET(this.tableSearch)
    },
    tableSearchStatus () {
      this.TRX_GET(this.tableSearch)
    },
    storeActive (v) {
      if (v) {
        this.TRX_GET(this.tableSearch)
      }
    },
    'items.selected' (v) {
      if (v) {
        if (v.id) {
          const customerType = this.customer.selected ? parseInt(this.customer.selected.type) || 0 : 0
          const itemExist = this.cart.find(r => parseInt(r.product_spec) === parseInt(v.id))
          if (itemExist) {
            this.CART_SYNC()
            this.$store.dispatch('TOAST', { show: true, message: 'Item already added!' })
          } else {
            const newItem = Object.assign({}, v)
            newItem.id = null
            newItem.quantity = 1
            newItem.product = v.product
            newItem.product_spec = v.id
            newItem.price_berjenjang = typeof newItem.price_berjenjang === 'string' ? this.$StrToArray(newItem.price_berjenjang) : newItem.price_berjenjang
            const priceBerjenjang = this.$priceCounter(newItem.price_berjenjang, newItem.price, newItem.quantity, customerType, 0)
            newItem.priceActive = priceBerjenjang.price
            newItem.priceActiveData = priceBerjenjang.data
            newItem.trx_price_active_custom = parseInt(v.trx_price_active_custom) || 0
            if (this.customer.selected) {
              this.$store.dispatch('product/CHECK_ITEM_PRICE', (parseInt(this.customer.selected.id) || 0) + '/' + (parseInt(v.id) || 0))
                .then((res) => {
                  newItem.price_special = 0
                  if (res.status) {
                    newItem.price_special = parseInt(res.data.data) || 0
                  }
                  this.cart.push(newItem)
                  this.items.selected = null
                  this.items.query = ''
                })
            } else {
              newItem.price_special = 0
              this.cart.push(newItem)
              this.items.selected = null
              this.items.query = ''
            }
          }
        }
      }
    },
    'items.query' (v, vBefore) {
      this.items.result = []
      if (v) {
        if (searchInterval) {
          clearTimeout(searchInterval)
        }
        searchInterval = setTimeout(() => {
          this.PRODUCT_GET(v)
        }, 300)
      }
    },
    async 'customer.selected' (v) {
      this.customerAddress = []
      this.areas = []
      this.areaquery = ''
      this.customerAddressSelected = null
      if (v) {
        if (v.id) {
          this.items.query = ''
          await this.CART_SYNC(parseInt(v.type) || 0)
        } else {
          await this.CART_SYNC(0)
        }
        await this.LOAD_CUSTOMER_ADDRESS(v.id)
        await this.VEHICLE_GET(v.id)
      } else {
        await this.CART_SYNC(0)
      }
    },
    'customer.query' (v) {
      if (v) {
        this.customer.result = []
        if (searchInterval) {
          clearTimeout(searchInterval)
        }
        searchInterval = setTimeout(() => {
          this.CUSTOMER_GET(v)
        }, 300)
      }
    }
  },
  created () {
    this.dates = [this.$localDT(new Date(), 'datedefault'), this.$localDT(new Date(), 'datedefault')]
    // this.TRX_GET()
  },
  async mounted () {
    /* eslint-disable */
  },
  methods: {
    async ExportExcel (isAll) {
      if (!isAll) {
        return false
      }
      const requestData = await this.TRX_GET(this.tableSearch, isAll)
      if (requestData && requestData.status && requestData.data.data.data) {
        const headers = ['ID', 'OPS UNIT', 'CUSTOMER', 'CUSTOMER TYPE', 'VEHICLE', 'PLAT NUMBER', 'SUBTOTAL', 'TAX', 'TOTAL', 'STATUS', 'CREATED']
        const rows = []
        const data = requestData.data.data.data || []
        for (let d = 0; d < data.length; d++) {
          const newRow = data[d]
          rows.push([
            {
              type: Number,
              value: newRow.id
            },
            {
              type: String,
              value: newRow.store_unit_name || ''
            },
            {
              type: String,
              value: (newRow.customer_name || '-') + ', ' + (newRow.customer_email || '-') +  ', ' + (newRow.customer_phone || '-')
            },
            {
              type: String,
              value: parseInt(newRow.customer_type) ? 'Business' : 'Default'
            },
            {
              type: String,
              value: (newRow.vehicle_brand || '-') + ', ' + (newRow.vehicle_type || '-') + ', ' + (newRow.vehicle_year || '-')
            },
            {
              type: String,
              value: newRow.vehicle_plat || '-'
            },
            {
              type: Number,
              value: parseFloat(newRow.amount_subtotal) || 0
            },
            {
              type: Number,
              value: parseFloat(newRow.amount_tax) || 0
            },
            {
              type: Number,
              value: parseFloat(newRow.amount_total) || 0
            },
            {
              type: String,
              value: parseInt(newRow.status) === 3 ? ((parseInt(newRow.amount_total) > parseInt(newRow.paid)) ? 'Waiting Payment' : this.$TrxStatus(newRow.status).name) : this.$TrxStatus(newRow.status).name
            },
            {
              type: Date,
              format: 'yyyy-mm-dd hh:mm:ss',
              value: new Date(newRow.created)
            }
          ])
        }
        await this.$CreateExcelFile({
          headers: headers,
          rows: rows
        }, 'transaction-' + (isAll === 'all' ? 'all-' : 'filtered-') + this.$localDT(null, 'datedefault') + '.xlsx')
      }
    },
    remove (item) {
      const index = this.dialogTrx.data.store_users.indexOf(item.idhash)
      if (index >= 0) this.dialogTrx.data.store_users.splice(index, 1)
    },
    ServiceInfoIsClear () {
      if (this.dialogTrx.data) {
        const serviceDate = this.dialogTrx.data.service_date
        const serviceDateNext = this.dialogTrx.data.service_date_next
        const guaranteeDate = this.dialogTrx.data.guarantee_date
        const serviceKm = parseInt(this.dialogTrx.data.service_km)
        const serviceKmNext = parseInt(this.dialogTrx.data.service_km_next)
        const guaranteeKm = parseInt(this.dialogTrx.data.guarantee_km)
        // console.log(serviceDate, serviceDateNext, guaranteeDate, serviceKm, serviceKmNext, guaranteeKm, parseInt(this.dialogUpdateTrx.status) === 3)
        return (serviceDate && serviceDateNext && guaranteeDate && serviceKm && serviceKmNext && guaranteeKm) || parseInt(this.dialogUpdateTrx.status) !== 3
      }
      return false
    },
    CartAdditionalDelete (index) {
      if (!confirm('Delete this item ?')) {
        return false
      }
      this.cartAdditional.splice(index, 1)
    },
    UpdatePriceCustom (productSpecID, isReset) {
      const el = this.$refs['customCartPrice' + productSpecID]
      const elSelected = document.getElementById('input-' + el[0]._uid)
      const newPrice = parseInt(elSelected.value) || 0
      const findCartIndex = this.cart.findIndex(r => parseInt(r.product_spec) === parseInt(productSpecID))
      if (findCartIndex > -1) {
        if (newPrice > 0 && !isReset) {
          this.cart[findCartIndex].trx_price_active_custom = newPrice
        } else {
          this.cart[findCartIndex].trx_price_active_custom = 0
        }
      } else {
        this.$store.dispatch('TOAST', { show: true, message: 'Ops, something went wrong!' })
      }
      this.SYNC_TOTAL()
    },
    ShowVehicle (vehicle) {
      if (vehicle) {
        this.dialogVehicle.selected = Object.assign({}, vehicle)
      } else {
        this.dialogVehicle.selected = {
          id: null,
          customer: this.customer.selected.id || 0,
          vehicle_brand: '',
          vehicle_type: '',
          vehicle_year: '',
          vehicle_plat: '',
          vehicle_rangka: '',
          vehicle_mesin: '',
          vehicle_fullname: '',
          notes: ''
        }
      }
      this.dialogVehicle.show = true
    },
    VEHICLE_GET (id) {
      this.dialogVehicle.selected = null
      this.dialogVehicle.data = []
      this.$store.dispatch('customer/VEHICLE_GET', '?customer=' + id).then((res) => {
        if (res.status) {
          if (res.data.data.data.length) {
            const findVehicle = res.data.data.data.find(r => parseInt(r.id) === parseInt(this.dialogTrx.data.service_vehicle))
            if (findVehicle) {
              this.dialogVehicle.selected = Object.assign({}, findVehicle)
            } else {
              this.dialogVehicle.selected = Object.assign({}, res.data.data.data[0])
            }
          }
          this.dialogVehicle.data = res.data.data.data
        }
      })
    },
    VEHICLE_PROCESS () {
      const processedVehicle = Object.assign({}, this.dialogVehicle.selected)
      if (!processedVehicle.vehicle_brand) {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid vehicle brand!' })
        return false
      }
      if (!processedVehicle.vehicle_type) {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid vehicle type!' })
        return false
      }
      if (parseInt(processedVehicle.vehicle_year) < 1890 || parseInt(processedVehicle.vehicle_year) > parseInt((new Date()).getFullYear())) {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid vehicle production year!' })
        return false
      }
      if (!processedVehicle.vehicle_plat) {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid vehicle plat number!' })
        return false
      }
      this.$store.dispatch('customer/VEHICLE_PROCESS', processedVehicle)
        .then((res) => {
          if (res.status) {
            this.VEHICLE_GET(this.customer.selected.id)
            this.dialogVehicle.show = false
            this.$store.dispatch('TOAST', { show: true, message: processedVehicle.id ? 'Updated' : 'Created!' })
          } else {
            if (res.data) {
              if (res.data.response) {
                if (res.data.response.data.message) {
                  this.$store.dispatch('TOAST', { show: true, message: res.data.response.data.message || 'Something went wrong!' })
                  return false
                }
              }
            }
            this.$store.dispatch('TOAST', { show: true, message: 'Something went wrong!' })
          }
        })
    },
    SYNC_TOTAL () {
      if (this.TRXEDITED && this.dialogTrx.data.payment_type !== 'hutang') {
        this.dialogTrx.data.cash = this.Subtotal
        this.dialogTrx.data.cash_refund = 0
      } else if (this.TRXEDITED && this.dialogTrx.data.payment_type === 'hutang') {
        this.dialogTrx.data.cash = 0
        this.dialogTrx.data.cash_refund = this.Subtotal
      }
    },
    XenditPaymentParse (payment) {
      if (payment.notes) {
        if (payment.notes.match(/FAILED/gi)) {
          return {}
        } else {
          try {
            const json = JSON.parse(payment.notes)
            if (json.invoice_url) {
              return Object.assign(json, {
                status: true
              })
            }
          } catch {
            return {}
          }
        }
      }
      return {}
    },
    areaSync () {
      const id = parseInt(this.dialogAddress.data.kecamatan_id)
      if (id) {
        const findSelectedArea = this.areas.find(r => parseInt(r.kecamatan_id) === parseInt(id))
        if (findSelectedArea) {
          this.dialogAddress.data.city_id = parseInt(findSelectedArea.city_id) || 0
          this.dialogAddress.data.city = findSelectedArea.city_type + ' ' + findSelectedArea.city_name
          this.dialogAddress.data.kecamatan = findSelectedArea.kecamatan_name
        }
      }
    },
    areaGet (q, id) {
      this.areas = []
      this.$store.dispatch('AREA_GET', q + (parseInt(id) ? '&id=' + id : '')).then((res) => {
        if (res.status) {
          this.areas = res.data.data || []
        }
      })
    },
    AddAddress (a) {
      this.dialogAddress.data = null
      if (!this.customer.selected) {
        this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
        return false
      }
      let obj = null
      if (a) {
        const r = this.customerAddressSelected
        obj = Object.assign({}, a)
        this.areaquery = r.kecamatan
        obj.kecamatan_id = r.kecamatan_id
        obj.city_id = r.city_id
      } else {
        this.customerAddressSelected = null
        obj = {
          id: null,
          customer: this.customer.selected.id || 0,
          name: '',
          phone: '',
          address: '',
          city: '',
          city_id: 0,
          kecamatan: '',
          kecamatan_id: 0,
          lat: '',
          lng: '',
          isdefault: 0
        }
      }
      this.dialogAddress.data = Object.assign({}, obj)
      this.dialogAddress.show = true
    },
    ProcessAddress () {
      const a = Object.assign({}, this.dialogAddress.data)
      a.customer = this.customer.selected ? parseInt(this.customer.selected.id) : 0
      if (!parseInt(a.customer)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan, undefined customer!' })
        return false
      }
      if (!a.name) {
        this.$store.dispatch('TOAST', { show: true, message: 'Nama penerima tidak boleh kosong!' })
        return false
      }
      if (!this.$validatePhone(a.phone)) {
        this.$store.dispatch('TOAST', { show: true, message: 'No HP tidak valid!' })
        return false
      }
      if (!a.address) {
        this.$store.dispatch('TOAST', { show: true, message: 'Alamat lengkap belum diisi!' })
        return false
      }
      if (!a.address) {
        this.$store.dispatch('TOAST', { show: true, message: 'Alamat lengkap belum diisi!' })
        return false
      }
      if (!parseInt(a.city_id)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Kota belum dipilih!' })
        return false
      }
      if (!parseInt(a.kecamatan_id)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Kecamatan belum dipilih!' })
        return false
      }
      this.loadingProcess = true
      this.$store.dispatch('customer/CUSTOMER_ADDRESS_PROCESS', a)
        .then((res) => {
          if (res.status) {
            if (res.data.data.id) {
              this.$store.dispatch('TOAST', { show: true, message: a.id ? 'Berhasil diupdate!' : 'Berhasil ditambah!' })
              this.LOAD_CUSTOMER_ADDRESS()
              this.customerAddressSelected = null
              this.dialogAddress.show = false
            } else {
              this.$store.dispatch('TOAST', { show: true, message: 'Terjadi Kesalahan!' })
            }
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi Kesalahan!' })
          }
          this.loadingProcess = false
        })
    },
    PrintStruk () {
      window.print()
    },
    LoadDriver () {
      this.$store.dispatch('account/ACCOUNTS_GET', '?role=driver&store=' + (this.$store.state.storeActive || 0))
        .then((res) => {
          if (res.status) {
            this.drivers = res.data.data.data
            if (!this.drivers.length) {
              this.$store.dispatch('TOAST', { show: true, message: 'Belum ada akun driver!' })
            }
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    },
    ProcessUpdateTrx (UpdateOnly) {
      this.dialogUpdateTrx.show = false
      const params = UpdateOnly ? { id: this.dialogTrx.data.id, shipping_driver_image: this.dialogTrx.data.shipping_driver_image, shipping_driver_notes: this.dialogTrx.data.shipping_driver_notes } : {
        id: this.dialogTrx.data.id,
        status: this.dialogUpdateTrx.status,
        shipping_awb: this.dialogUpdateTrx.shipping_awb,
        shipping_driver: this.dialogUpdateTrx.shipping_driver || 0
      }
      if (parseInt(this.dialogTrx.data.status)) {
        if (!parseInt(this.dialogTrx.data.store)) {
          this.$store.dispatch('TOAST', { show: true, message: 'Operation Unit belum dipilih!' })
          this.dialogUpdateTrx.show = true
          return false
        } else {
          params.store = parseInt(this.dialogTrx.data.store) || 0
        }
      }
      this.loadingProcess = true
      this.$store.dispatch('trx/TRX_UPDATE', params)
        .then((res) => {
          if (res.status) {
            this.TRX_GET(this.tableSearch)
            this.$store.dispatch('TOAST', { show: true, message: 'Berhasil diupdate!' })
            this.dialogUpdateTrx.show = false
            if (!UpdateOnly) {
              this.dialogTrx.show = false
            }
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
          this.loadingProcess = false
        })
    },
    ProcessUpdateTrxPoin () {
      if (!confirm('Update Poin ?')) {
        return false
      }
      const params = {
        id: this.dialogTrx.data.id,
        point_used_price_100: this.dialogTrx.data.point_used_price_100,
        point_used: this.newPoinUpdate
      }
      this.loadingProcess = true
      this.$store.dispatch('trx/TRX_UPDATE', params)
        .then((res) => {
          if (res.status) {
            this.TRX_GET(this.tableSearch)
            this.dialogTrx.show = false
            this.$store.dispatch('TOAST', { show: true, message: 'Berhasil diupdate!' })
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
          this.loadingProcess = false
        })
    },
    ShowUpdateTrxStatus () {
      if (this.dialogTrx.show && parseInt(this.dialogTrx.data.id)) {
        this.dialogUpdateTrx.status = this.dialogTrx.data.status
        this.dialogUpdateTrx.shipping_awb = this.dialogTrx.data.shipping_awb || ''
        this.dialogUpdateTrx.shipping_driver = 0
        this.dialogUpdateTrx.show = true
      }
    },
    LOAD_CUSTOMER_ADDRESS (cid) {
      cid = cid || (this.customer.selected ? this.customer.selected.id : 0)
      this.customerAddress = []
      this.$store.dispatch('customer/CUSTOMER_ADDRESS', cid)
        .then((res) => {
          if (res.status) {
            const first = res.data.data.find(r => parseInt(r.isdefault))
            const newData = res.data.data.sort(function (x, y) { return x === first ? -1 : y === first ? 1 : 0 })
            this.customerAddress = newData || []
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    },
    ShowCustomer () {
      this.newCustomer.data.id = null
      this.newCustomer.data.name = ''
      this.newCustomer.data.email = ''
      this.newCustomer.data.phone = ''
      this.newCustomer.data.type = 0
      this.newCustomer.show = true
    },
    ProcessCustomer () {
      const newData = Object.assign({}, this.newCustomer.data)
      if (!this.$validateName(newData.name)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Masukan nama customer yang valid!' })
        return false
      }
      if (!this.$validateEmail(newData.email)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Email customer tidak valid!' })
        return false
      }
      if (!this.$validatePhone(newData.phone)) {
        this.$store.dispatch('TOAST', { show: true, message: 'No HP customer tidak valid!' })
        return false
      }
      newData.phone = this.$reformatPhone(newData.phone)
      this.loadingProcess = true
      this.$store.dispatch('customer/CUSTOMER_PROCESS', newData)
        .then((res) => {
          if (res.status) {
            if (res.data.data.response) {
              this.newCustomer.show = false
              this.customer.selected = res.data.data.data
              this.$store.dispatch('TOAST', { show: true, message: 'Berhasil dibuat' })
            } else {
              this.$store.dispatch('TOAST', { show: true, message: res.data.data.message })
            }
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
          this.loadingProcess = false
        })
    },
    async TRX_GET (q, dataExcel) {
      if (this.$store.state.user & this.$store.state.user.role === 'operation_unit') {
        this.storeUnit = this.$store.state.user.store.id
        if (!parseInt(this.storeUnit)) {
          this.$store.dispatch('TOAST', { show: true, message: 'Invalid request!' })
          return false
        }
      }
      const fromTo = this.GetDates(this.dates)
      const payment = this.tableSearchPayment ? ('&payment_type=' + this.tableSearchPayment) : ''
      let status = this.tableSearchStatus === 0 || this.tableSearchStatus > 0 ? this.tableSearchStatus : ''
      let paid = ''
      if (this.tableSearchStatus === 4) {
        status = 3
        paid = '&paid=1'
      } else if (status === 3) {
        paid = '&paid=0'
      }
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const pageRow = (parseInt(page) > 0 ? '&page=' + page : '')
      const limitRow = (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '')
      const withSortBy = sortBy ? (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '') : ''
      if (dataExcel) {
        const exportPage = '&page=1'
        const exportLimit = dataExcel === 'all' ? '&limit=1000000000' : limitRow
        const query = '?c=' + ('&store=' + (parseInt(this.storeUnit) || 0)) + exportPage + exportLimit + withSortBy
        const exportQuery = dataExcel === 'all' ? '?page=1&limit=1000000000' : (query + (q ? ('&q=' + q) : '') + (parseInt(status) === 0 || parseInt(status) > 0 ? '&status=' + status : '') + (fromTo) + (payment) + paid)
        return await this.$store.dispatch('trx/TRX_GET', exportQuery).then(res => res)
      } else {
        this.tableSummary.subtotal = 0
        this.tableSummary.total = 0
        this.tableLoading = true
        const query = '?c=' + ('&store=' + (parseInt(this.storeUnit) || 0)) + pageRow + limitRow + withSortBy
        await this.$store.dispatch('trx/TRX_GET', query + (q ? ('&q=' + q) : '') + (parseInt(status) === 0 || parseInt(status) > 0 ? '&status=' + status : '') + (fromTo) + (payment) + paid).then((res) => {
          if (res.status) {
            this.table = res.data.data.data
            this.tableTotal = parseInt(res.data.data.total) || 0

            this.tableSummary.subtotal = parseInt(res.data.data.summary.subtotal) || 0
            this.tableSummary.total = parseInt(res.data.data.summary.total) || 0
          } else {
            this.table = []
            this.tableTotal = 0
            this.tableSummary.subtotal = 0
            this.tableSummary.total = 0
          }
          this.tableLoading = false
        })
      }
    },
    async LOAD_TRX_PAYMENT (trxID) {
      this.trxPayments = []
      await this.$store.dispatch('trx/TRX_PAYMENT_GET', trxID)
        .then((res) => {
          if (res.status) {
            let sumTotal = 0
            const data = res.data.data.map((r) => {
              if (parseInt(r.status)) {
                sumTotal += parseInt(r.amount)
              }
              return r
            })
            this.dialogTrx.data.paid = sumTotal || 0
            this.trxPayments = data || []
          }
        })
    },
    TRX_PAYMENT_SHOW (p) {
      if (p) {
        this.dialogPayment.data = Object.assign({}, p)
      } else {
        this.dialogPayment.data = {
          id: null,
          payment_type: this.dialogTrx.data.payment_type,
          trx: this.dialogTrx.data.id,
          customer: this.dialogTrx.data.customer,
          amount: parseInt(this.dialogTrx.data.amount_total) - parseInt(this.dialogTrx.data.paid) || 0,
          notes: '',
          status: 1
        }
      }
      this.dialogPayment.show = true
    },
    TRX_PAYMENT_DELETE (pid) {
      if (!confirm('Delete Payment ?')) {
        return false
      }
      this.$store.dispatch('trx/TRX_PAYMENT_DELETE', pid)
        .then((res) => {
          if (res.status) {
            this.LOAD_TRX_PAYMENT(this.dialogTrx.data.id)
            this.$store.dispatch('TOAST', { show: true, message: 'Deleted!' })
            this.dialogPayment.show = false
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Something went wrong!' })
          }
        })
    },
    TRX_PAYMENT_PROCESS () {
      if (this.dialogPayment.data) {
        if (!parseInt(this.dialogPayment.data.amount)) {
          this.$store.dispatch('TOAST', { show: true, message: 'Invalid Amount!' })
          return false
        }
      } else {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid Transaction!' })
        return false
      }
      this.loadingProcess = true
      this.$store.dispatch('trx/TRX_PAYMENT_PROCESS', this.dialogPayment.data).then((res) => {
        if (res.status) {
          this.LOAD_TRX_PAYMENT(this.dialogTrx.data.id)
          this.$store.dispatch('TOAST', { show: true, message: this.dialogTrx.data.id ? 'Berhasil diupdate!' : 'Berhasil disimpan!' })
          this.dialogPayment.show = false
        } else {
          this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
        }
        this.loadingProcess = false
      })
    },
    ClearTrx () {
    },
    async TRX_SHOW (trx) {
      this.newPoinUpdate = 0
      this.cart = []
      this.customerAddress = []
      this.customer.selected = null
      this.customer.query = ''
      this.customerAddressSelected = null
      this.dialogUpdateTrx.show = false
      this.dialogTrx.data = Object.assign({}, trx)
      if (trx) {
        if (trx.store_users && trx.store_users.replace('[]', 'strescaper').match('strescaper')) {
          trx.store_users = '[]'
        }
        this.dialogTrx.data.store_users = this.$StrToArray(trx.store_users) || []
        this.cartAdditional = (this.$StrToArray(trx.items_additional) || []).map((r) => {
          r.imgs = r.imgs || '[]'
          r.quantity = parseInt(r.quantity) || 1
          return r
        })
        this.newPoinUpdate = parseInt(trx.point_used || 0)
        await this.$store.dispatch('trx/TRX_GET_ITEMS', trx.id)
          .then((res) => {
            if (res.status) {
              const d = res.data.data
              this.cart = (d || []).map(r => {
                if (r.trx_price_option) {
                  r.trx_price_option = this.$StrToJson(r.trx_price_option)
                }
                return r
              })
            }
          })
        if (parseInt(trx.customer) && trx.customer_name) {
          this.customer.query = trx.customer_name
        } else {
          this.customer.selected = null
        }
      } else {
        this.cartAdditional = []
        this.dialogTrx.data = Object.assign({}, newTrx)
        this.dialogTrx.data.store = parseInt(this.$store.state.storeActive) || 0
        this.dialogTrx.data.cashier = parseInt(this.$store.state.user.id) || 0
        this.dialogTrx.data.platform = 0
      }
      if (trx) {
        await this.LOAD_TRX_PAYMENT(trx.id)
      }
      if (!this.storeUsers.length) {
        await this.LOAD_USERS()
      }
      this.dialogTrx.show = true
    },
    async LOAD_USERS () {
      const query = '?store=0&page=1&limit=1000&sort=name&sorting=ASC&role=mechanic'
      await this.$store.dispatch('account/ACCOUNTS_GET', query).then((res) => {
        if (res.status) {
          this.storeUsers = res.data.data.data.filter(r => r.role === 'mechanic')
        } else {
          this.storeUsers = []
        }
      })
    },
    TRX_PROCESS () {
      const trx = Object.assign({}, this.dialogTrx.data)
      // if (trx.payment_type === 'online' && !parseInt(trx.cash)) {
      //   this.$store.dispatch('TOAST', { show: true, message: 'Pembayaran Hutang atau 0 tidak bisa memakai type Online!' })
      //   return false
      // }
      trx.platform = 0
      trx.cashier = parseInt(this.$store.state.user.id) || 0
      trx.customer = this.customer.selected ? (parseInt(this.customer.selected.id) || 0) : 0
      if (!parseInt(trx.customer)) {
        this.$store.dispatch('TOAST', { show: true, message: 'No customer selected!' })
        return false
      }
      if (!parseInt(trx.store)) {
        this.$store.dispatch('TOAST', { show: true, message: 'No opes. unit selected!' })
        return false
      }
      if (!this.dialogVehicle.selected) {
        this.$store.dispatch('TOAST', { show: true, message: 'No vehicle selected!' })
        return false
      } else {
        trx.service_vehicle = parseInt(this.dialogVehicle.selected.id)
      }
      trx.payment_status = parseInt(trx.cash) === 0 ? 0 : (parseInt(trx.cash_refund) < 0 ? 2 : 1)
      if (!this.cart.length) {
        this.$store.dispatch('TOAST', { show: true, message: 'No item selected!' })
        return false
      }

      if (!confirm(parseInt(trx.id) ? 'Proses order ?' : 'Update Order ?')) {
        return false
      }

      trx.items_additional_price = this.TotalAdditional || 0
      trx.store_users = this.$ArrayToStr(trx.store_users)

      const trxData = {
        trx: trx,
        items: this.cart,
        items_additional: this.cartAdditional || []
      }
      this.loadingProcess = true
      this.$store.dispatch('trx/TRX_PROCESS', trxData).then((res) => {
        if (res.status) {
          if (res.data.data.status) {
            this.TRX_GET(this.tableSearch)
            this.$store.dispatch('TOAST', { show: true, message: 'Berhasil dibuat!' })
            this.dialogTrx.show = false
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        } else {
          this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
        }
        this.loadingProcess = false
      })
    },
    PRODUCT_GET (q) {
      this.$store.dispatch('product/PRODUCT_GET', ('?store=' + this.$store.state.storeActive) + '&q=' + (q || '') + '&page=1&limit=12&status=1').then((res) => {
        if (res.status) {
          this.items.result = res.data.data.data || []
        } else {
          this.items.result = []
        }
      })
    },
    CUSTOMER_GET (q) {
      this.$store.dispatch('customer/CUSTOMER_GET', ('?q=' + (q || ''))).then((res) => {
        if (res.status) {
          if (this.dialogTrx.data) {
            if (parseInt(this.dialogTrx.data.id) && parseInt(this.dialogTrx.data.customer)) {
              const c = Object.assign(res.data.data.find(r => parseInt(r.id) === parseInt(this.dialogTrx.data.customer)))
              if (c) {
                c.type = parseInt(this.dialogTrx.data.customer_type)
                this.customer.selected = c
              }
              return false
            }
          }
          this.customer.result = res.data.data || []
        } else {
          this.customer.result = []
        }
      })
    },
    CART_QUANTITY (act, index) {
      if (act === 'min') {
        if (this.cart[index].quantity > 2) {
          this.cart[index].quantity = (parseInt(this.cart[index].quantity) - 1)
        } else {
          this.cart[index].quantity = 1
        }
      } else if (act === 'plus') {
        this.cart[index].quantity += 1
      }
      this.CART_SYNC()
    },
    async CART_SYNC () {
      const customerType = this.customer.selected ? parseInt(this.customer.selected.type) || 0 : 0
      if (this.TRXEDITED) {
        for (let c = 0; c < this.cart.length; c++) {
          const item = Object.assign(this.cart[c])
          if (this.customer.selected) {
            const checkSpecialPrice = await this.$store.dispatch('product/CHECK_ITEM_PRICE', (parseInt(this.customer.selected.id) || 0) + '/' + (parseInt(item.product_spec) || 0))
            if (checkSpecialPrice.status) {
              this.cart[c].price_special = parseInt(checkSpecialPrice.data.data) || 0
            }
          } else {
            this.cart[c].price_special = 0
          }
          const p = typeof item.price_berjenjang === 'string' ? this.$StrToArray(item.price_berjenjang) : item.price_berjenjang
          const priceBerjenjang = this.$priceCounter(p, item.price, item.quantity, customerType, 0)
          this.cart[c].priceActive = priceBerjenjang.price
          this.cart[c].priceActiveData = priceBerjenjang.data
        }
        this.SYNC_TOTAL()
      }
    },
    SHIPPING_CHECK (kecamatanID) {
      this.customerShippingSelected = null
      this.customerShippingList = []
      if (!parseInt(kecamatanID) || isNaN(parseInt(kecamatanID))) {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalie selected address!' })
        return false
      }
      const selectedStore = this.$store.state.storeData.find(r => parseInt(r.id) === parseInt(this.storeActive))
      if (!selectedStore) {
        this.$store.dispatch('TOAST', { show: true, message: 'Lokasi toko gagal termuat!' })
        return false
      } else if (!parseInt(selectedStore.kecamatan_id)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Asal toko belum ditentukan!' })
        return false
      }
      this.$store.dispatch('shipping/SHIPPING_CHECK', {
        kecamatan_from: selectedStore.kecamatan_id,
        kecamatan_to: kecamatanID,
        courier: this.dialogTrx.data.shipping_method_courier
      }).then((res) => {
        if (res.status) {
          if (res.data.data.resource === 'local' || res.data.data.resource === 'external') {
            if (res.data.data.resource === 'external') {
              this.SHIPPING_CHECK(kecamatanID)
            } else if (res.data.data.resource === 'local') {
              const d = res.data.data.data
              this.customerShippingList = d
              if (d.length) {
                this.customerShippingSelected = d[0]
              }
            } else {
              this.customerShippingList = []
            }
          }
        }
      })
    },
    GetDates () {
      const fromTo = this.dates
      if (fromTo) {
        if (fromTo.length === 2) {
          if ((new Date(fromTo[0])).getTime() < (new Date(fromTo[1])).getTime()) {
            return '&from=' + fromTo[0] + '&to=' + fromTo[1]
          } else {
            return '&from=' + fromTo[1] + '&to=' + fromTo[0]
          }
        }
      }
      return ''
    },
    GetFromTo (fromTo) {
      if (fromTo) {
        if (fromTo.length === 2) {
          if ((new Date(fromTo[0])).getTime() < (new Date(fromTo[1])).getTime()) {
            return ('&from=' + fromTo[0] + '&to=' + fromTo[1])
          } else {
            return (this.search, '&from=' + fromTo[1] + '&to=' + fromTo[0])
          }
        }
      }
      return ''
    },
    UploadImage (isIDReport, PartAdditionalIndex) {
      if (!this.loadingImageUpload) {
        let u = document.getElementById('file-uploader')
        if (u) {
          u.remove()
        }
        setTimeout(() => {
          u = document.createElement('input')
          u.setAttribute('type', 'file')
          u.setAttribute('id', 'file-uploader')
          u.setAttribute('class', 'file-uploader')
          u.setAttribute('accept', '.png,.gif,.jpeg,.jpg')
          u.addEventListener('change', (e) => {
            this.uploadPhotoRender(e, isIDReport, PartAdditionalIndex)
          })
          document.body.appendChild(u)
          u.click()
        }, 100)
      }
    },
    async uploadPhotoRender (event, isIDReport, PartAdditionalIndex) {
      /* eslint-disable */
      const $ = this
      await $.$filetoBase64(event, async function (res) {
        if (res.status) {
          $.loadingImageUpload = true
          await $.$store.dispatch('UPLOAD_FILE', {
            file: res.data,
            source: 'shipping'
          }).then((resUpload) => {
            if (resUpload.status) {
              if (resUpload.data.data.res) {
                if (parseInt(isIDReport)) {
                  const foundCartIndex = $.cart.findIndex((r) => parseInt(r.id) === isIDReport)
                  if (foundCartIndex > -1) {
                    const imgs = $.$getImageDataAll($.cart[foundCartIndex].imgs)
                    imgs.push({ name: '', img: resUpload.data.data.secure_url })
                    $.cart[foundCartIndex].imgs = $.$ArrayToStr(imgs)
                    $.$store.dispatch('TOAST', { show: true, message: 'Uploaded!' })
                  }
                } else if (parseInt(PartAdditionalIndex) === 0 || parseInt(PartAdditionalIndex) > 0) {
                  const imgs = $.$getImageDataAll($.cartAdditional[PartAdditionalIndex].imgs)
                  imgs.push({ name: '', img: resUpload.data.data.secure_url })
                  $.cartAdditional[PartAdditionalIndex].imgs = $.$ArrayToStr(imgs)
                  $.$store.dispatch('TOAST', { show: true, message: 'Uploaded!' })
                } else {
                  $.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
                }
                // $.dialogTrx.data.shipping_driver_image = resUpload.data.data.secure_url
                // $.ProcessUpdateTrx($.dialogTrx.data.id)
              } else {
                $.$store.dispatch('TOAST', { show: true, message: 'Gagal upload, silahkan coba lagi!' })
              }
            }
            $.loadingImageUpload = false
          })
        } else {
          res.data === 'file_size'
            ? $.$store.dispatch('TOAST', { show: true, message: 'Image size no more than 1Mb' }) // ? $.$store.commit('TOAST', { show: true, message: 'Ops, file image is too large. Max size (files < 5Mb & video < 20Mb)' })
            : res.data === 'canceled'
              ? console.log('Upload canceled.')
              : console.log(res.data)
        }
      })
    }
  }
}
</script>

<style lang="scss">
.barcode-class {
  display: flex;
  justify-content: center;
  svg {
    display: block;
  }
}
</style>
